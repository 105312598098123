@import "./general.scss";

.product-documentation__doc-heading {
  width: 268px;
  overflow-y: scroll;
  height: 775px;
  font-family: $font-family-inter;
  border-right: 2px solid #949494;

  display: flex;
  padding: 0 24px 0 0;
  flex-direction: column;

  ul {
    list-style-type: none !important;
    margin: 0;
  }

  a {
    text-decoration: none;
    font-weight: 400;

    &:hover {
      text-decoration: none;
    }
  }

  .product-documentation__topic-list {
    padding-bottom: 12px;
  }

  .product-documentation__topic-div {
    margin-bottom: 5px;
    display: flex;
    align-items: flex-start;
  }

  .fa-caret-icon {
    color: #1b1b1b;
    margin-top: 1.5px;
  }

  .product-documentation__topic-link {
    color: #1b1b1b;
    font-size: 16px;
    word-break: break-word;
    font-family: $font-family-inter;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 3px;
  }
  .product-documentation__sub-topic-heading {
    li + li {
      margin-top: 3px;
    }
  }

  .product-documentation__sub-topic-list {
    padding-bottom: 9px;
  }

  .product-documentation__sub-topic-link {
    color: #1b1b1b;
    word-break: break-word;
    font-family: $font-family-inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
