@import "../../../../styles/variables";

.heading {
  margin-top: 0;
  margin-bottom: 0;
  color: #000;
  font-family: $font-family-roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 22px;
  gap: 12px;
}

div > .name {
  margin-top: 0;
}

.remove-margin {
  margin-top: -20px;
}

div > .modal-header {
  border-bottom: unset;
}
