.multi-select-container {
  overflow: hidden;
  max-height: 300px;
  border: 1px solid #d1d1d1;
  border-radius: 3px;
  overflow-y: auto;
}

.multi-select.invalid {
  border-color: #f9585f;
}

.multi-select-wrapper {
  .error {
    display: block;
    margin-top: 4px;
    color: #cb2a32;
    transition: color ease-in-out 0.15s;
  }
}

.multi-select {
  display: flex;
  height: 315px;
  flex-direction: column;
  border: 1px solid #d1d1d1;
  border-radius: 3px;
  overflow-y: auto;

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid transparent;
    cursor: pointer;

    &:not(.selected):hover {
      background-color: #f0f0f0;
    }

    &.selected {
      border-bottom: 2px solid #c1deb7;
      background-color: #d4edc4;
    }

    .option-title {
      flex-grow: 1;
      color: #333;
    }

    .option-action {
      display: flex;
      width: 24px;
      height: 24px;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      border-radius: 12px;

      .circle-icon-red {
        color: #f9585f;
        font-size: 1rem;
      }

      .circle-icon-grey {
        color: #949494;
        font-size: 1rem;
      }
    }
  }
}
