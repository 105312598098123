@import "../../styles/variables";

.container {
  overflow: hidden;
  width: 100%;
  border: 0.5px solid #adadad;
  margin-bottom: 30px;
  border-radius: 8px;
  text-align: center !important;
}

.sorter-active {
  color: var(--primary-300, #00789a);
}

.caret-icon {
  margin-bottom: 2.5px;
  color: var(--primary-300, #00789a) !important;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-block-end: 0.5px solid #949494;

  .left-container {
    display: flex;
    align-items: baseline;
    gap: 20px;
  }

  .right-container {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .header-title {
    color: #000;
    font-family: $font-family-inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
  }

  .left-text {
    color: #000;
    font-family: $font-family-inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }

  .delete {
    display: flex;
    height: 30px;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    padding: 0 14px;
    cursor: pointer;
    gap: 7px;

    span {
      margin-top: 5px;
      color: var(--danger-600, #cb2a32);
      font-family: $font-family-roboto;
      font-size: 11.2px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 14px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
  }
}

.table-container {
  overflow: auto;
  height: 275px;

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      position: sticky;
      top: 0;
      background: #fff;
      text-transform: uppercase;

      th {
        padding: 8px 1rem;
        border-bottom: 0.5px solid var(--gray-200, #595959);
        background: var(--gray-010, #f8f8f8);
        border-block-end: 0.5px solid #949494;

        .left-header {
          display: flex;
          justify-content: flex-start !important;
        }

        button {
          padding: 0;
          border: 0;
          cursor: pointer;
          font-size: 11px;
          font-weight: 600;
        }

        &.selected {
          > button,
          > i {
            color: #00789a;
          }
        }
      }
    }

    tbody {
      td {
        padding: 1.5rem 1rem;
        border-block-end: 0.5px solid #949494;

        .values {
          width: 160px;
          padding: 30px 0;
          color: #000;
          font-family: $font-family-roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: center;

          &:hover {
            color: #2ea5c8;
            text-decoration: none;
          }
        }

        .user-actions {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          padding-inline-end: 2rem;
        }

        .table-delete-button {
          border: 0 !important;
        }
      }
    }
  }

  .left-align {
    text-align: left;
  }

  .active-column {
    color: #00789a;
  }
}
