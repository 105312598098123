.nav {
  display: grid;
  padding-top: 2rem;
  padding-right: 2rem;
  padding-left: 2rem;
  grid-template-columns: 1fr 1fr;
}

.nav-steps {
  justify-self: start;
}

.nav-next {
  display: flex;
  gap: 1rem;
  justify-self: end;
}
