@import "../../../../styles/variables";

.heading {
  margin-top: 0;
  margin-bottom: 0;
  color: #000;
  font-family: $font-family-roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 22px;
  gap: 12px;
}

div > .name {
  margin-top: 0;
}

.remove-margin {
  margin-top: -20px;
}

div > .modal-header {
  border-bottom: unset;
}

.add-user-container {
  display: flex;
  align-items: center;
}

.add-user-button-container {
  padding-bottom: 1rem;
  padding-left: 0.5rem;
}

.add-user-button {
  border: 0 !important;

  &:disabled {
    background-color: transparent;

    & > i::before {
      color: #949494;
    }

    &:hover {
      border: 0 !important;
      background-color: #d1d1d1;
    }
  }
}

.table-delete-button {
  border: 0 !important;
  text-transform: capitalize !important;

  &:disabled {
    background-color: transparent;

    & > i::before {
      color: #949494;
    }

    &:hover {
      background-color: #d1d1d1;
    }
  }
}

.sorter-active {
  color: var(--primary-300, #00789a);
}

.caret-icon {
  margin-bottom: 2.5px;
  color: var(--primary-300, #00789a) !important;
}

.center {
  display: flex;
  justify-content: center;
}

.name-width {
  overflow: hidden;
  width: 80px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.email-width {
  overflow: hidden;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-container {
  overflow: auto;
  height: 290px;
  border: 0.5px solid #adadad;
  margin-top: 15px;
  border-radius: 10px;

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      position: sticky;
      top: 0;
      background: #fff;
      text-transform: uppercase;

      th {
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        border-block-end: 0.5px solid #949494;

        button {
          padding: 0;
          border: 0;
          background-color: #fff;
          cursor: pointer;
          font-size: 11px;
          font-weight: 600;
        }

        &.selected {
          > button,
          > i {
            color: #00789a;
          }
        }
      }
    }

    tbody {
      td {
        padding: 0.5rem 0.9rem;
        border-block-end: 0.5px solid #949494;

        .user-actions {
          display: flex;
          justify-content: flex-end;
          text-align: end;
        }
      }
    }
  }
}
