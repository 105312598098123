@import "../general.scss";

.product-metadata__container {
  background-color: rgba(232, 242, 244, 0.4);
  padding: 18px 0 21px 0;
  border-radius: 4px;
  flex: 1;

  .product-metadata__heading-container {
    padding: 0 18px;
    .product-metadata__heading {
      text-decoration: underline dashed;
      text-underline-offset: 5px;
      font-weight: $font-weight-normal;
      font-size: 16px;
      line-height: normal;
      color: $color-black;
      margin-top: 0px;
      margin-right: 22px;
      font-family: $font-family-inter;

      @media #{$media-extra-wide-lg} {
        font-size: 16px;
      }

      @media #{$media-extra-wide-expanded} {
        font-size: 24px;
      }
    }
  }

  .product-metadata__items {
    padding: 0 18px;
    .product-metadata__item {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.25px solid #adadad;

      &-key {
        font-weight: $font-weight-bold;
        font-family: $font-family-inter;
        font-size: 14px;
        line-height: normal;
        color: $color-black;
        margin: 10px 0px;

        @media #{$media-extra-wide-lg} {
          font-size: 14px;
        }

        @media #{$media-extra-wide-expanded} {
          font-size: 16px;
        }
      }

      &-value {
        margin: 10px 0px;
        font-family: $font-family-inter;
        padding-left: 10px;
        text-align: right;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
