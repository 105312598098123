@import "variables";
@import "fonts";
@import "animations";

:root {
  font-size: 16px !important; // NEF Override

  --toastify-toast-width: max-content !important;
}

body {
  margin: 0;
  background-color: white !important; // NEF Override
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

/* stylelint-disable selector-class-pattern */

/* Workaround for the bug where FormSelect in a Modal shows the menu selection
behind the Modal. This is due to the menu having a fixed but lower z-index than the
Modal, and NEF hides the ability to customize the menu z-index. The only workaround is
to target the className of the portal where the menu resides. This specific style is
for the "operator" dropdown menu portal in ColumnFiltersModal */
.operator__menu-portal,
.categoryId__menu-portal,
.planPaymentType__menu-portal,
.planCurrency__menu-portal,
.planPaymentSchedule__menu-portal,
.modalFormSelect__menu-portal,
.publisherSelect__menu-portal,
.administratorContactEmail__menu-portal,
.productType__menu-portal,
.organizationUser__menu-portal,
.lazy_admin_select__menu-portal {
  z-index: 2060 !important;
}
/* stylelint-enable selector-class-pattern */
