@import "../../styles/variables";

.products-table {
  width: 100%;
  border-spacing: 0;

  th {
    border-bottom: 0.5px solid var(--gray-600, #949494);

    &:hover {
      cursor: pointer;
    }

    &.selected {
      > button,
      > i {
        color: #00789a;
      }
    }
  }

  td {
    border-top: none;
  }

  .label {
    padding-bottom: 8px;
    color: #000;
    font-family: $font-family-inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
  }

  .name {
    color: var(--primary-300, #00789a);
    font-size: 11px;
  }

  .left-align {
    width: 400px;
    text-align: left;
  }

  .right-align {
    display: flex;
    justify-content: flex-end;
  }

  .date-container {
    max-height: 205px;
    min-width: 300px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 0.75px solid var(--gray-800, #d1d1d1);
    background: var(--charts-dual-primary-10, #e8f2f4);
    border-left: none;
    display: flex;
    padding: 0px 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 9px;
    align-self: stretch;
    height: 204px;
    margin: 12px 0;

    .info-container {
      display: flex;
      width: 100%;
      padding-bottom: 9px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.5px solid var(--gray-800, #d1d1d1);
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }

    .info-title {
      color: #1b1b1b;
      font-family: Source Sans Pro;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .info-value {
      color: #1b1b1b;
      text-align: right;
      font-family: Source Sans Pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      flex: 1 0 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .name-container {
    min-width: 300px;
    max-width: 100%;
    height: 158px;
    border: 0.75px solid var(--gray-800, #d1d1d1);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: none;
    background: var(--gray-000, #fff);
    display: flex;
    padding: 23px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    padding-right: 50px;
    margin: 12px 0;

    .heading-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    .heading {
      color: #000;
      font-family: $font-family-inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 0;
      margin-bottom: 0;
    }

    .pills-container {
      display: flex;
      gap: 10px;
    }

    .icons {
      color: var(--gray-600, #949494);
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }

    .heading-left {
      display: flex;
      gap: 10px;
    }

    .heading-right {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 25px;
    }

    .description {
      min-width: 720px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      flex: 1 0 0;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 110px;
      max-height: 110px;

      color: #1b1b1b;
      font-family: $font-family-inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;
    }
  }
}

.sorter-active {
  color: var(--primary-300, #00789a);
}

.caret-icon {
  margin-bottom: 2.5px;
  color: var(--primary-300, #00789a) !important;
}

.link {
  color: var(--charts-dual-gray-70, #4d4d4d);

  &:hover {
    color: var(--charts-dual-gray-70, #4d4d4d);
    text-decoration: none;
  }
}
