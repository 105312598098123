.option-inner {
  width: 100%;
  padding: 0.25rem;
  border: 1px solid rgb(0 0 0 / 12.5%);
  cursor: auto;

  i {
    color: rgb(108 117 125 / 20%);
  }

  img {
    width: 100%;
  }
}

.option {
  width: 100%;
  box-sizing: border-box;
  padding: 0.25rem;
  cursor: pointer;
  text-align: center;

  * {
    box-sizing: border-box;
  }

  &-selected {
    .option-inner {
      border-color: rgb(0 0 0 / 50%);
    }

    i {
      color: #00bf3f;
    }
  }

  &:hover {
    .option-inner {
      background-color: rgb(0 0 0 / 5%);
    }
  }
}

.option-body {
  padding: 0.5rem 1rem;
}

.sdg-indicator-label {
  display: block;
  margin: 0 0 4px;
  color: #6e6e6e;
  font-size: 0.9rem;
  font-weight: 500;
}
