.container {
  width: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  table-layout: fixed;

  th,
  td {
    width: 130px;
    padding: 0.3125rem;
    border: 1px solid rgb(195 199 202 / 100%);
  }

  th {
    color: rgb(167 169 172 / 100%);
    font-weight: 300;
  }
}
