@import "../../../../styles/variables";

.uploader {
  width: 100%;
  margin-top: 3rem;
  font-size: 0.875rem;
}

.label {
  margin: 0 0 0.25rem;
  color: #6e6e6e;
  font-size: 0.9rem;
  font-weight: 500;
}

.asterisk {
  margin-right: 0.25rem;
  color: #b0008e;
  font-family: $font-family-roboto;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 0;
  vertical-align: sub;
}

.template-button {
  margin-bottom: 0.5rem;
}

.description {
  display: flex;
  margin-bottom: 1rem;
  font-weight: 300;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.dropzone {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border: 2px dashed #949494;
  background-color: #f8f8f8;
  font-family: $font-family-roboto;
  font-weight: 300;
  text-align: center;
  transition: background-color 0.25s;
}

.dropzone-active {
  background-color: rgb(0 148 189 / 10%);
}

.dropzone-loading {
  opacity: 0.2;
}

.browse {
  margin-left: 1rem;
  background-color: #fff;
  color: #0094bd;
}

.existing-file {
  font-weight: 500;
}

.dropzone + .existing-file {
  margin-top: 1rem;
}
