.footer-buttons-container {
  display: flex;
  padding-top: 1rem;
  gap: 2rem;
}

.header {
  h3 {
    font-size: 25px !important;
    font-weight: 300 !important;
  }
}

.table {
  height: 162px;
  border: 0.5px solid var(--gray-600, #949494);
  background: var(--gray-010, #f8f8f8);
  border-radius: 5px;
  overflow-y: scroll;
}

.row {
  display: flex;
  justify-content: space-between;
  padding: 12px 10px;
  border-bottom: 0.5px solid var(--gray-600, #949494);
}

.name {
  display: flex;
  align-items: center;
}

.body {
  display: flex;
  flex-direction: column;
}

.buttons-container {
  display: flex;
  gap: 1rem;
}

.buttons {
  border: 0 !important;

  &:disabled {
    background-color: transparent;

    & > i::before {
      color: #949494;
    }

    &:hover {
      background-color: #d1d1d1;
    }
  }
}
