@import "../../../styles/variables";

.buttons {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 22px;
  gap: 12px;
}

.description {
  margin-top: 0;
}

div > .name {
  margin-top: 0;
}

.remove-margin {
  margin-top: -20px;
}

div > .modal-header {
  border-bottom: unset;
}
