@import "../styles/variables";

.nav {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem 1rem;
  box-shadow: 0 4px 6px rgb(0 0 0 / 10%);
}

.divider {
  height: 30px;
  border-left: 1px solid #a7a9ac;
  margin-right: 16px;
  margin-left: 16px;
}

.logo {
  display: inline-block;
}

.title {
  display: flex;
  align-items: center;
  padding-left: 0.7rem;
  color: black;
  font-family: $font-family-rubik;
  font-size: 1.1rem;
  font-weight: normal;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    color: black;
    text-decoration: none;
  }
}

.links {
  display: flex;
  margin: 0 1rem;
  list-style: none;
}

.link {
  padding: 0.5rem 1rem;

  & > a {
    color: black;
    font-family: $font-family-roboto;
    font-size: 0.9rem;

    &:hover {
      color: black;
    }
  }

  & > button {
    display: inline;
    padding: 0;
    border: none;
    margin: 0;
    background-color: transparent;
    cursor: pointer;
    font-family: $font-family-roboto;
    font-size: 0.9rem;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
}
