@import "../../../styles/variables";

.label {
  margin-top: 20px;
  margin-bottom: 6px;
  color: #6e6e6e;
  font-family: $font-family-roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.star {
  color: #b0008e;
}

.icon {
  margin-left: 10px;
  color: #6e6e6e;
  font-size: 10px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
}
