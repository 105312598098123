@import "../../styles/variables";

.header {
  font-family: $font-family-rubik;
  font-size: 1.75rem;
  font-weight: 300;
}

.container {
  margin-left: 1rem;
}

.no-results {
  margin-top: 2.5rem;
}
