@import "../../styles/variables";

.pill {
  display: flex;
  align-items: center;
  padding: 4px 7px;
  border-radius: 14px;
  font-family: $font-family-roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.draft-pill {
  border: 2px solid var(--secondary-500, #af3c98);
  background: var(--gray-000, #fff);
  color: var(--secondary-600, #c82faa);
}

.waiting-approval-pill {
  background: var(--warning-300, #fedc5c);
  color: var(--gray-200, #595959);
}

.none {
  display: none;
}

.published-pill {
  border: 2px solid var(--success-600, #009d34);
  background: var(--gray-000, #fff);
  color: var(--success-600, #009d34);
}

.cap-product-pill {
  background: var(--primary-300, #3dbde1);
  color: var(--gray-000, #fff);
}
