.browse {
  color: #0094bd;
  cursor: pointer;
}

.dropzone {
  display: flex;
  width: 98%;
  height: 266px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #000;
  text-align: center;
  transition: background-color 0.25s;
}

.upload-icon {
  font-size: 80px;
  font-weight: 400;
}

.logo-upload-preview-image {
  max-width: 220px;
  max-height: 220px;
}

.dropzone-active {
  background-color: rgb(0 148 189 / 10%);
}

.file-icon {
  display: block;
  margin: 1rem auto;
}

div > .modal-footer {
  justify-content: flex-start;
}
