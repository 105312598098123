@import "../general.scss";

.data-visualization__container {
  width: 100%;
  padding-bottom: 11px;
  margin-bottom: 46px;

  .data-visualization__heading-button {
    border-radius: 3px;
    padding: 6px 14px;
    border: 1px solid #bcbcbc;
    color: #a8a8a8;
    background-color: #fff;
    text-align: center;
    font-size: 11.2px;
    font-family: $font-family-roboto;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      cursor: pointer;
      border: 1px solid #1b1b1b;
      color: #1b1b1b;
      text-decoration: none;
    }

    &:active {
      color: #fff;
      background-color: #1b1b1b;
    }

    .data-visualization__heading-button-icon {
      margin-left: 12px;
    }
  }

  .data-visualization__heading-button-disabled {
    border: 1px solid #d1d1d1;
    color: #949494;
    background-color: #d1d1d1;
    pointer-events: none;
  }

  .data-visualization__highcharts-container {
    width: 100% !important;
  }

  .data-visualization__heading-box {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    margin-bottom: 12px;

    .data-visualization__dropdown-menu {
      display: block;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      max-width: 220px;
      height: 26px;
      border-radius: 3px;
      margin: 0;
      padding: 6px 16px 6px 12px;
      border: 0;
      outline: 1px solid #bcbcbc;
      border-right: 12px solid transparent;
      color: #a8a8a8;
      text-align: center;
      font-size: 11.2px;
      font-family: $font-family-roboto;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      box-sizing: border-box;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2211%22%20height%3D%227%22%20viewBox%3D%220%200%2011%207%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M5.35156%206.45312L0.78125%201.90625C0.570312%201.67188%200.570312%201.32031%200.78125%201.10938L1.32031%200.570312C1.53125%200.359375%201.88281%200.359375%202.11719%200.570312L5.75%204.17969L9.35938%200.570312C9.59375%200.359375%209.94531%200.359375%2010.1562%200.570312L10.6953%201.10938C10.9062%201.32031%2010.9062%201.67188%2010.6953%201.90625L6.125%206.45312C5.91406%206.66406%205.5625%206.66406%205.35156%206.45312Z%22%20fill%3D%22%23A8A8A8%22%2F%3E%3C%2Fsvg%3E");
      background-repeat: no-repeat, repeat;
      background-position: right 0 top 50%;

      /* Hide arrow icon in IE browsers */
      &::-ms-expand {
        display: none;
      }

      &:hover {
        cursor: pointer;
        outline: 1px solid #1b1b1b;
        color: #1b1b1b;
      }
    }

    .data-visualization__heading-right {
      display: flex;
      column-gap: 9px;
      position: absolute;
      top: 0;
      right: 0;

      .data-visualization__heading-icon {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .data-visualization__heading-icon:hover #Circle-Container {
        fill: #595959;
        stroke: #fff;
      }

      .data-visualization__heading-icon:hover #Chart-ICO {
        fill: #fff;
      }

      .data-visualization__heading-icon:hover #Ellipse-9 {
        fill: #595959;
        stroke: #fff;
      }

      .data-visualization__heading-icon:hover #Vector {
        fill: #fff;
      }

      .data-visualization__heading-icon-disabled {
        pointer-events: none;
        fill: #adadad;
        stroke: #adadad;
      }
    }
  }

  .data-visualization__popover {
    position: absolute;
    z-index: 100;
    right: 0;
    top: 30px;
    width: 488px;
    height: 383px;
    background-color: #fff;
    flex-shrink: 0;
    box-shadow: 0px 5px 6px 0px rgba(129, 129, 129, 0.2),
      0px 3px 16px 0px rgba(129, 129, 129, 0.12),
      0px 9px 12px 0px rgba(129, 129, 129, 0.14);

    .data-visualization__popover-container {
      padding: 35px 24px 24px;

      .data-visualization__popover-title-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .data-visualization__popover--left-container {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;

          .data-visualization__popover-title {
            max-width: 295px;
            color: var(--gray-900, #1b1b1b);
            font-family: Source Sans Pro;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-top: 0;
            margin-bottom: 0;
          }
        }

        .data-visualization__popover-button {
          padding: 7px 14px;
          gap: 7px;
          border-radius: 3px;
          border: 1px solid var(--primary-500, #0094bd);
          background: var(--primary-500, #0094bd);
          color: var(--gray-000, #fff);
          text-align: center;
          font-family: Roboto;
          font-size: 11.2px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: 1px;
          text-transform: uppercase;

          &:hover {
            text-decoration: none;
            background-color: #000;
            border: 1px solid #000;
          }
        }
      }

      .data-visualization__popover-line {
        margin-top: 30px;
        border-top: 2px solid #f0f0f0;
      }

      .data-visualization__popover-body {
        margin-top: 30px;
        .data-visualization__popover-body-title {
          color: #000;
          font-family: Source Sans Pro;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 13px;
          text-transform: uppercase;
          margin-top: 0;
          margin-bottom: 15px;
        }

        .data-visualization__popover-options {
          display: flex;
          gap: 24px;

          .data-visualization__popover-option {
            color: var(--gray-400, #818181);
            font-family: Source Sans Pro;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 13px;
            text-transform: uppercase;
            margin-top: 0;
            margin-bottom: 15px;
            cursor: pointer;
          }

          .popover-option-active {
            color: var(--gray-000, #1b1b1b);
            text-decoration-line: underline;
          }
        }

        .input-container {
          position: relative;
        }

        .data-visualization__popover-input {
          width: 375px;
          padding-left: 17px;
          padding-right: 45px;
          height: 44px;
          flex-shrink: 0;
          border-radius: 0px 4px 4px 0px;
          border: 1px solid var(--gray-100, #d1d1d1);
          background: var(--gray-000, #fff);
          overflow: hidden;
          color: #000;
          text-overflow: ellipsis;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          &:focus {
            outline: none;
            border: 1px solid #3dbde1;
          }
        }

        .bottom-margin {
          margin-bottom: 30px;
        }

        .copy-icon:hover #Copy {
          fill: #595959;
        }

        .copy-icon {
          position: absolute;
          top: 12px;
          right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .data-visualization__table-container {
    max-height: 400px;
    overflow: auto;
    position: relative;

    table {
      border-collapse: collapse;
      border-spacing: 0;
      cursor: pointer;
      user-select: none;

      thead th {
        position: sticky;
        top: 0;
      }

      th {
        padding: 10px 10px 12px 10px;
        background: $color-white;
        color: $color-black;
        font-size: 14px;
        font-family: $font-family-inter;
        font-weight: 700;
        line-height: 18px;
        text-transform: lowercase;
        border-top: 1px solid $color-grey82;
        border-right: 1px solid $color-grey82;
        border-bottom: 1px solid $color-grey-dark;

        &:first-child {
          border-left: 1px solid $color-grey82;
          border-bottom: 1px solid $color-grey82;
        }

        &.selected {
          background: $color-dataset-border;
          border: 1px solid #99c8dc;
          border-bottom: 1px solid $color-grey-dark;
          border-style: double;
        }
      }

      tr {
        color: #1b1b1b;
        font-size: 14px;
        font-family: $font-family-inter;
        font-weight: 300;
        line-height: 18px;

        &:nth-child(even) {
          background: #f8f8f8;
        }

        &.selected {
          background: $color-dataset-border;

          td {
            border: 1px solid $color-dataset-border-selected;
            border-style: double;
          }
        }

        &:first-child {
          td.selected {
            border-top: 1px solid $color-grey-dark;
          }

          &.selected td:not(:first-child) {
            border-top: 1px solid $color-grey-dark;
            border-left: 1px solid $color-dataset-border-selected;
            border-right: 1px solid $color-dataset-border-selected;
            border-bottom: 1px solid $color-dataset-border-selected;
            border-style: double;
          }
        }

        &:last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      td {
        padding: 10px 10px 12px 10px;
        border: 1px solid $color-grey82;
        text-wrap: nowrap;

        &.selected {
          background: $color-dataset-border;
          border: 1px solid $color-dataset-border-selected;
          border-style: double;
        }
      }

      .empty-cell {
        width: 100%;
        padding: 0;
        margin: 0;
        border-right: 0;
      }
    }

    &::-webkit-scrollbar {
      height: 8px;
    }
  }

  .data-visualization__guest-section {
    position: relative;
    img {
      width: 100%;
      height: 333px;
    }

    .guest-pricing-section {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);

      .guest-pricing-container {
        padding: 28px 38px 36px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 314px;
        flex-shrink: 0;
        background: var(--gray-000, #fff);
        box-shadow: 0px 1px 8px 0px rgba(129, 129, 129, 0.2),
          0px 3px 3px 0px rgba(129, 129, 129, 0.12),
          0px 3px 4px 0px rgba(129, 129, 129, 0.14);

        .guest-pricing-title {
          color: #000;
          text-align: center;
          font-family: Source Sans Pro;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin-top: 0;
          margin-bottom: 12px;

          b {
            color: #000;
            font-family: Source Sans Pro;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }
        }

        .guest-pricing-button-container {
          display: flex;
          gap: 12px;

          .guest-pricing-pri-button {
            padding: 7px 14px;
            border-radius: 3px;
            border: 1px solid #0094bd;
            background: #0094bd;
            color: var(--gray-000, #fff);
            text-align: center;
            font-family: $font-family-roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 1px;
            text-transform: uppercase;

            &:hover {
              cursor: pointer;
              text-decoration: none;
              border: 1px solid #1b1b1b;
              background-color: #1b1b1b;
            }
          }

          .guest-pricing-sec-button {
            padding: 7px 14px;
            border-radius: 3px;
            border: 1px solid #bcbcbc;
            color: #a8a8a8;
            background-color: #fff;
            text-align: center;
            font-family: $font-family-roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 1px;
            text-transform: uppercase;

            &:hover {
              text-decoration: none;
              cursor: pointer;
              color: #1b1b1b;
            }
          }
        }
      }
    }
  }
}
