.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2.5rem;
  background-color: #fcfcfc;

  h4 {
    color: #6e6e6e;
    font-size: 18px;
    font-weight: 500;
  }
}

.header-buttons-container {
  display: flex;
  gap: 12px;
}

.loading-indicator {
  display: flex;
  min-height: 240px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fcfcfc;
}
