.footer-buttons-container {
  display: flex;
  padding-top: 1rem;
  gap: 2rem;
  margin-inline-start: 10px;
}

.header {
  h3 {
    font-size: 25px !important;
    font-weight: 300 !important;
  }
}

.body {
  display: flex;
  flex-direction: column;
}
