@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade-in {
  animation: fade-in 0.3s ease-out both;
}

.fade-out {
  animation: fade-out 0.3s ease-out both;
}
