@import "../../../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  border: 1px solid #adadad;
  border-radius: 0.5rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  border-bottom: 1px solid #adadad;
}

.sub-header {
  padding: 1rem;
  border-bottom: 1px solid #adadad;
}

.table-list {
  overflow: auto;
  height: 300px;
}

.table-status {
  width: 0.5625rem;
  grid-area: status;
}

.table-name {
  overflow: hidden;
  grid-area: name;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-description {
  overflow: hidden;
  color: #595959;
  grid-area: description;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0 0.25rem;
  grid-area: actions;
}

.table-error {
  padding: 1rem;
  grid-area: error;
}

.table-error-summary {
  padding: 1rem;
  grid-area: error-summary;
}

.table-item {
  display: grid;
  align-items: center;
  border-bottom: 1px solid #adadad;
  grid-template: auto / 1rem 25% auto 15%;
  grid-template-areas: "status name description actions";

  .table-status {
    align-self: stretch;
  }

  .table-name {
    padding: 0.5rem;
  }

  .table-description {
    padding: 0.5rem;
  }

  .table-actions {
    padding: 0.5rem;
  }

  .table-error {
    display: none;
  }

  .table-error-summary {
    display: none;
  }
}

/* stylelint-disable selector-class-pattern */
.table-item--valid {
  .table-status {
    background-color: $success;
  }
}

.table-item--error {
  grid-template-areas:
    "status name description actions"
    "status error . .";

  .table-status {
    background-color: #f8333c;
  }

  .table-name {
    padding: 0.125rem 0.5rem 0;
  }

  .table-description {
    padding: 0.125rem 0.5rem 0;
  }

  .table-actions {
    padding: 0.125rem 0.5rem 0;
  }

  .table-error {
    display: block;
    padding: 0 0.5rem 0.5rem;
    color: red;
    cursor: pointer;
    font-size: 0.75rem;
  }

  .table-error-summary {
    display: none;
  }
}
/* stylelint-enable selector-class-pattern */

.table-delete-button {
  border: 0 !important;
}

.sort-icon {
  color: #0092bc !important;
}
