@import "../../styles/variables";

.log {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #595959;

  &:last-child {
    border-bottom: none;
  }
}

.attribute + .attribute {
  margin-top: 1.25rem;
}

.attribute-label {
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
}

.attribute-value {
  font-size: 0.875rem;

  &--success {
    color: $success;
  }

  &--error {
    color: $error;
  }
}
