@import "../../../../styles/variables";

.row {
  display: flex;
  padding: 1rem;
  gap: 1rem;
}

/* stylelint-disable selector-class-pattern */
.row--stacked {
  padding-top: 0;
}
/* stylelint-enable selector-class-pattern */

.tooltip {
  width: 33%;
  color: #818181;
  font-size: $font-family-roboto;
  font-size: 0.75rem;
  word-wrap: break-word;
}

.field {
  width: 67%;

  textarea {
    width: 100%;
  }
}
