.divider {
  border-top: 1px solid #6e6e6e;
  margin-bottom: 1rem;
}

textarea.notes-textarea {
  min-height: 90px !important;
  max-height: 90px !important;
  overflow-y: scroll;
  resize: none;
}
