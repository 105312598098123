.button {
  padding: 0.75rem 1.4rem;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 0.875rem;

  &:disabled {
    cursor: default;
  }

  &:hover {
    text-decoration: none;
  }

  img {
    vertical-align: middle;
  }
}
