@import "../../../styles/variables";

.container {
  display: flex;
  width: 250px;
  height: 334px;
  flex-direction: column;
  padding: 15px;
  border: 0.5px solid #d1d1d1;
  border-radius: 4px;
}

.title {
  margin-bottom: 8px;
  font-family: $font-family-roboto;
  font-size: 16px;
  font-weight: 400;
}

.licence {
  margin-bottom: 8px;
  color: #28b0d6;
  cursor: pointer;
  font-family: $font-family-roboto;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}

.plans-list {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 4px;
}

.plan {
  cursor: pointer;
  font-family: $font-family-roboto;
  font-size: 12px;
  text-decoration: underline;
}
