.dropzone {
  display: flex;
  width: 100%;
  height: 266px;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: background-color 0.25s;
}

.dropzone-active {
  background-color: rgb(0 148 189 / 10%);
}

.file-icon {
  display: block;
  margin: 1rem auto;
}

.browse {
  color: #0094bd;
  cursor: pointer;
}
