.wrapper {
  display: flex;
  overflow: hidden;
  height: 100%;
  flex-direction: column;
  padding-right: 1rem;
  padding-left: 1rem;
}

.content {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.25rem 1.5rem;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
}

.list {
  overflow: auto;
  flex-grow: 1;
}

.record {
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  border-bottom: 1px solid #000;

  &:last-child {
    border-bottom: none;
  }
}

.record-info {
  flex-grow: 1;
}

.record-action {
  display: flex;
  flex: 0 0 160px;
  justify-content: flex-end;
}

.record-timestamp {
  color: #a8a8a8;
  font-size: 0.875rem;
}
