.wizard {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 1rem;
}

.body {
  overflow: auto;
  flex-grow: 1;
}
