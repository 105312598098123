.header-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0 1rem;
}

.title {
  > h2 {
    margin-bottom: 0;
  }
}

.progress-bar {
  display: flex;
  flex-wrap: nowrap;
  gap: 0.25rem;
}

.progress {
  min-width: 48px;
  height: 6px;
  flex-grow: 1;
  border: none;
  background: rgb(209 209 209);
  box-shadow: none;
  cursor: pointer;
  outline: none;

  &--running {
    background: rgb(0 190 62);
  }

  &:disabled {
    cursor: not-allowed;
  }
}
