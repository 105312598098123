@import "../../../styles/variables";

.button {
  width: 95px;
  height: 25px;
  border: none;
  background-color: #d9d9d9;
  border-radius: 5px;
  cursor: pointer;
  font-family: $font-family-roboto;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
}

.button-active {
  background-color: #3dbde1;
  color: white;
}
