@import "../../styles/variables";

.container {
  display: flex;
}

.user-section {
  width: 100%;
  padding: 2rem;
}

.user {
  display: flex;
  justify-content: space-between;
}

.heading {
  margin-top: 0;
  margin-bottom: 0;
  color: #000;
  font-family: $font-family-roboto;
  font-feature-settings: "liga" off;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
}

.buttons-container {
  display: flex;
  gap: 21px;
}

.circle-icon {
  margin-right: 7px;
  font-size: 0.8rem;
}
