@import "../../styles/variables";

/* stylelint-disable no-descending-specificity */
.schema-form {
  display: grid;
  align-items: center;
  margin-top: 5px;
  grid-template-columns: auto 3fr 2fr 2fr 1fr 1fr 1fr;

  &.s3-form {
    grid-template-columns: auto 2fr 2fr 2fr 1fr 1fr 1fr auto;
  }
}

.reduced-columns {
  grid-template-columns: auto 3fr 2fr 2fr 1fr 1fr 1fr;

  &.s3-form {
    grid-template-columns: auto 2fr 2fr 2fr 1fr 1fr 1fr 1fr;
  }
}

.row {
  display: contents;

  > .column {
    padding: 0.5rem;
  }

  .column-decimal {
    margin-top: -1.5em;
  }
}

.columns-header {
  display: contents;

  > .column {
    padding: 0.5rem;
    border-bottom: 1px solid #d1d1d1;
    font-size: 0.6875rem;
    font-weight: bold;
    line-height: 2rem;
    vertical-align: middle;
    white-space: nowrap;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.rows {
  display: contents;
}

.dateformat-controls {
  display: flex;
  justify-content: space-between;
  margin-top: -1.3rem;
  gap: 20px;
}

.decimal-controls {
  display: flex;
  justify-content: space-between;
  margin-top: -1.3rem;
  gap: 20px;
}

.remove-column {
  border: none;
  background: none;
  cursor: pointer;

  i {
    color: $error;
    font-size: 1rem;
  }
}

.tooltip {
  margin: 0 5px;
}

.visibility-none {
  display: none;
}
/* stylelint-enable no-descending-specificity */
