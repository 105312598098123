@import "../../styles/variables";

.title {
  font-family: $font-family-rubik;
  font-weight: 300;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.back-button {
  align-self: start;
  padding: 0.875rem 1rem 0.875rem 0;

  svg {
    padding-right: 0.5rem;
  }
}

.file-screen-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 2rem;
}
