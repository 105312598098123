@import "../../styles/variables";

.container {
  overflow: hidden;
  width: 100%;
  height: 660px;
  border: 0.5px solid #adadad;
  border-radius: 8px;
}

.sorter-active {
  color: var(--primary-300, #00789a);
}

.caret-icon {
  margin-bottom: 2.5px;
  color: var(--primary-300, #00789a) !important;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-block-end: 0.5px solid #949494;

  span {
    font-weight: 500;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
  }
}

.product-link {
  text-decoration: none !important;
}

.disable-link {
  pointer-events: none;
}

.table-container {
  overflow: auto;
  height: 600px;
  padding: 0 1rem 15px;
  margin-top: 15px;

  table {
    width: 100%;
    border-collapse: collapse;

    a {
      color: #1b1b1b;
      font-family: $font-family-source-sans-pro;
      font-size: 14px;
      font-weight: 400;
      text-decoration: underline;
    }

    .product-name {
      color: #000;
      font-family: $font-family-roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration: none;

      &:hover {
        color: #0092bc;
      }
    }

    thead {
      position: sticky;
      top: 0;
      background: #fff;
      text-transform: uppercase;

      th {
        padding-bottom: 1rem;
        border-block-end: 0.5px solid #949494;

        button {
          padding: 0;
          border: 0;
          background-color: #fff;
          cursor: pointer;
          font-size: 11px;
          font-weight: 600;
        }

        &.selected {
          > button,
          > i {
            color: #00789a;
          }
        }
      }
    }

    tbody {
      td {
        padding: 1.5rem 0;
        border-block-end: 0.5px solid #949494;

        &:has(.view-product-button) {
          padding-inline-end: 0.5rem;
          text-align: right;
        }

        .view-product-button {
          a {
            color: #a8a8a8;
          }
        }
      }
    }
  }
}
