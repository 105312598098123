@import "../../../styles/variables";

$color-off-black: #0d3244;

.container {
  padding: 0 20px;
}

.licence {
  display: flex;
  flex-flow: row wrap;
  gap: 50px;
}

.licence-name {
  color: $color-off-black;
  font-family: $font-family-quantico;
  font-size: 26px;
  font-weight: 400;
}

.product-name {
  color: #0090ba;
  font-family: $font-family-quantico;
  font-size: 36px;
}

.body {
  flex: 1 0 70%;
  margin-top: 20px;
  color: $color-off-black;
  font-family: $font-family-rasa;
  font-size: 18px;

  h1,
  h2,
  h3,
  h4 {
    margin-top: 20px;
    font-family: $font-family-quantico;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  p {
    margin: 0 0 10px;
  }
}

.summary {
  height: 100%;
  flex: 1 0 20%;
  padding: 15px 20px 23px;
  border: 1px solid #e4e9ee;
  margin-top: 20px;
  border-radius: 2px;
}

.summary-title {
  margin-bottom: 20px;
  color: rgb(13 50 68 / 70%);
  font-family: $font-family-quantico;
  font-size: 20px;
}

.summary-content {
  color: $color-off-black;
  font-family: $font-family-rasa;
  font-size: 18px;
  line-height: 28px;

  p {
    margin-top: 10px;
  }

  ul {
    padding-left: 15px;
    list-style-type: square;
  }
}
