.drawer {
  width: 661px;
  max-width: 661px !important;
}

.footer-buttons-container {
  display: flex;
  padding-top: 1rem;
  gap: 1rem;
  margin-inline-start: 10px;
}

.header {
  h3 {
    font-size: 25px !important;
    font-weight: 300 !important;
  }
}

.body {
  display: flex;
  flex-direction: column;
}

.search {
  flex: 1;
  overflow-y: auto;
}
