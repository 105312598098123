@import "../../../styles/variables";

.content-grid {
  display: grid;
  align-items: center;
  border-bottom: 1px solid #a8a8a8;
  grid-template-columns: auto 1fr 1fr 1fr;
}

.header {
  > .column {
    align-self: stretch;
    border-bottom: 1px solid #a8a8a8;
  }
}

.row {
  display: contents;

  > .column {
    padding: 1rem 0.5rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &--align-center {
      display: flex;
      align-items: center;
    }

    &--justify-end {
      display: flex;
      justify-content: flex-end;
    }
  }

  &:first-child {
    > .column {
      padding-top: 1.5rem;
    }
  }

  &:last-child {
    > .column {
      padding-bottom: 1.5rem;
    }
  }
}

.delete-icon {
  padding-top: 24px; // height of FormLabel, to offset this icon to the same y-axis as FormField
  line-height: 40px; // height of FormField, to center-align icon to the FormField

  button {
    border: none;
    background: none;
    cursor: pointer;
  }

  i {
    color: $error;
    font-size: 1rem;
  }
}
