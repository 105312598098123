@import "../../../styles/variables";

.container {
  display: flex;
}

.content-section {
  width: 100%;
  padding: 2rem;
}

.organization {
  display: flex;
  justify-content: space-between;
}

.heading {
  margin-top: 0;
  margin-bottom: 0;
  color: #000;
  font-family: $font-family-roboto;
  font-feature-settings: "liga" off;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
}

.buttons-container {
  display: flex;
  gap: 20px;
}

.chevron-icon {
  margin-left: 7px;
}

.circle-icon {
  margin-right: 7px;
  font-size: 0.8rem;
}

.content {
  display: flex;
  flex-direction: column;
  font-family: $font-family-roboto;
}

.metadata {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.label {
  margin-right: 5px;
  font-weight: bolder;
}

table {
  width: 100%;
  border-spacing: 0;

  th {
    &:hover {
      cursor: pointer;
    }

    &.selected {
      > button,
      > i {
        color: #00789a;
      }
    }
  }

  td {
    border-top: 0.5px solid var(--gray-600, #949494);
  }

  .label {
    padding-bottom: 8px;
    color: #000;
    font-family: $font-family-inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
  }

  .name {
    color: var(--primary-300, #00789a);
    font-size: 11px;
  }

  .values {
    width: 160px;
    padding: 30px 0;
    color: #000;
    font-family: $font-family-roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;

    &:hover {
      text-decoration: none;
    }
  }

  .left-align {
    width: 400px;
    text-align: left;
  }

  .right-align {
    display: flex;
    justify-content: flex-end;
  }
}

.sorter-active {
  color: var(--primary-300, #00789a);
}

.caret-icon {
  margin-bottom: 2.5px;
  color: var(--primary-300, #00789a) !important;
}

.link {
  color: var(--charts-dual-gray-70, #4d4d4d);

  &:hover {
    color: var(--charts-dual-gray-70, #4d4d4d);
    text-decoration: none;
  }
}
