.body {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
}

.form {
  width: 50%;
  min-width: 640px;
}
