.action-container {
  display: flex;
}

.action-menu-container {
  display: flex;
  align-items: center;
  justify-content: center;

  & .icon-offset {
    right: 40px;
  }
}

.copy-filter-button {
  padding-right: 35px;
  padding-left: 35px;

  &:hover {
    cursor: pointer;
  }
}
