.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block-end: 1rem;

  h4 {
    color: #6e6e6e;
    font-size: 1rem;
    font-weight: 500;
  }
}

.header-buttons-container {
  display: flex;
  gap: 15px;
}

.name-width {
  overflow: hidden;
  width: 330px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sorter-active {
  color: var(--primary-300, #00789a);
}

.caret-icon {
  margin-bottom: 2.5px;
  color: var(--primary-300, #00789a) !important;
}

.table-delete-button {
  border: 0 !important;
}

.table-container {
  overflow: auto;
  height: 235px;
  padding: 0 0.5rem 15px;
  background-color: white;
  border-block-end: 0.5px solid #e6e6e6;
  margin-block-start: 15px;

  &.error {
    border: 1px solid #f9585f;
    border-radius: 3px;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      position: sticky;
      top: 0;
      background: #fff;
      text-transform: uppercase;

      th {
        padding-bottom: 1rem;
        border-block-end: 0.5px solid #949494;

        button {
          padding: 0;
          border: 0;
          background-color: #fff;
          cursor: pointer;
          font-size: 11px;
          font-weight: 600;
        }
      }
    }

    tbody {
      td {
        padding: 0.5rem 0;
        border-block-end: 0.5px solid #949494;

        &:has(.admin-action-buttons) {
          padding-inline-end: 0.5rem;
          text-align: right;
        }
      }
    }
  }
}

.header-icon {
  margin-right: 0.25rem !important;
  color: #b0008e;
  font-size: 18px;
  line-height: 0;
  vertical-align: sub;
}

.error-message {
  margin-top: 4px;
  color: #f8333c;
}

.required-star {
  margin-right: 0.25rem;
  color: #b0008e;
  font-size: 18px;
  line-height: 0;
  vertical-align: sub;
}
