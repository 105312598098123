.wrapper {
  overflow: auto;
  max-height: 377px;
  padding: 1rem;
  border: 1px solid #d1d1d1;
  border-radius: 4px;

  & + .wrapper {
    margin-top: 1.75rem;
  }
}

.name {
  margin-bottom: 1rem;
  font-weight: 1.125rem;
  font-weight: 300;
}

.table {
  width: 100%;
  border-collapse: collapse;

  thead {
    th {
      padding: 0.25rem 1rem;
      border-bottom: 1px solid #d1d1d1;
      font-size: 0.8125rem;
      font-weight: bold;
      text-transform: uppercase;

      &.actions {
        width: 40%;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: #e6e6e6;
      }
    }

    td {
      padding: 1.5rem 1rem;
    }
  }
}
