.header {
  display: flex;
  width: 50%;
  min-width: 640px;
  flex-direction: column;
  gap: 0.5rem 0;
}

.header-row {
  display: flex;
  justify-content: space-between;
}

.section-dropdown {
  flex-basis: 225px;
}

.counter {
  display: flex;
  flex-basis: 100px;
  align-items: center;
  justify-content: flex-end;
}

.progress-bar {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  gap: 0.25rem;
}

.progress {
  height: 6px;
  flex-grow: 1;
  border: none;
  background: rgb(209 209 209);
  box-shadow: none;
  cursor: pointer;
  outline: none;

  &--running {
    background: rgb(0 190 62);
  }

  &:disabled {
    cursor: not-allowed;
  }
}
