@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;700;800&family=Roboto+Mono&display=swap");

// MEDIA QUERIES
$media-small: "only screen and (max-width: 47.999em)";
$media-medium: "only screen and (min-width: 48em) and (max-width: 64.68em)";
$media-wide: "only screen and (min-width: 64.68em)";
$media-expanded: "only screen and (min-width: 81em)";
$media-extra-wide: "only screen and (min-width: 1441px)";
$media-extra-wide-md: "only screen and (min-width: 1601px)";
$media-extra-wide-lg: "only screen and (min-width: 1921px)";
$media-extra-wide-expanded: "only screen and (min-width: 2561px)";

// COLOURS
$color-white: #fff;
$color-black: #000;
$color-blue-turqoise: #2ebfd5;
$color-turqoise: #1e90ba;
$color-vibrant-turqoise: #0090ba;
$color-bright-turqoise: #00bfd5;
$color-primary-blue: #0e99b9;
$color-orange: #e1701e;
$color-light-orange: #e36f1e;
$color-red: #e03d2e;
$color-light-red: rgba(224, 61, 46, 0.1);
$color-green: #1eb789;
$color-active-green: #6cb33f;
$color-brand: #eb5210;
$color-panel-background: #e1e1e6;
$color-background-white: #f2f2f2;
$color-grey-light: #e6e6e6;
$color-grey: #a6aaad;
$color-grey58: #949494;
$color-grey82: #d1d1d1;
$color-grey-dark: #1b1b1b;
$color-grey-extra-dark: #1b1b1b;
$color-grey-xx-dark: #232323;
$color-purple-blue: #594099;
$color-pale-blue: #eff8fb;
$color-dark-green: #0d3244;
$color-title: #8698a1;
$color-grey-medium: #d8d8d8;
$color-smoke-grey: #686868;
$color-pale-grey: #dff0f6;
$color-panel-background: #e1e1e6;
$color-blue-grey-light: #edf1f5;
$color-grey-accent: #a7a9ac;
$color-blue-grey-dark: #97a0a9;
$color-blue-grey: #d0d8df;
$color-exclusive-blue: #0b8fff;
$color-light-blue: #f0f8fb;
$color-vivid-green: #3fcc6f;
$color-blue-grey-extra-light: #f7fafc;
$color-green-grey: #576f7b;
$color-tag-grey: #f2f2f2;
// ####################
$color-slate-grey: #959699;
$color-wireframe-grey: #d9d9d9;
$color-welcome-banner-background-grey: #e1e1e6;
$color-welcome-banner-text-grey: #272727;
$color-blue-grey-mid: #e4e9ee;
$color-dropdown-border: #0a2d3f;
// ####################
$color-light-grey: #979797;
$color-yellow: #fdb913;
$color-pale-yellow: #fff36e;
$color-rich-yellow: #f3bb44;
$color-blue-footer-links: #1e90ba;
$color-transparent: rgba(0, 0, 0, 0);
$color-black-transparent: rgba(0, 0, 0, 0.7);
$color-black-transparent-10: rgba(0, 0, 0, 0.1);
$color-black-16: rgba(13, 50, 68, 0.16);
$color-mint-green: #dbf9f0;
$color-green: #05b888;
$color-faded-blue: #219bc4;
$color-brown-grey: #888;
$color-blue-footer-links: #2ebfd5;
$color-top-bar-brown: #424242;
$color-faint-grey: #f0f0f0;
$color-floating-icon-grey: #b5c2cd;
$color-grey-extra-dark: #1b1b1b;
$color-org-count-grey: #113447;
$color-org-db-color-grey-medium: #cecece;

// -- Shadows/black Opacities -- //
$color-transparent: rgba(0, 0, 0, 0);
$color-black-05: rgba(13, 50, 68, 0.05);
$color-black-10: rgba(13, 50, 68, 0.1);
$color-black-16: rgba(13, 50, 68, 0.16);
$color-black-20: rgba(13, 50, 68, 0.2);
$color-black-25: rgba(13, 50, 68, 0.25);
$color-black-30: rgba(13, 50, 68, 0.3);
$color-black-50: rgba(13, 50, 68, 0.5);
$color-black-70: rgba(13, 50, 68, 0.7);
$color-black-90: rgba(13, 50, 68, 0.9);

$color-white-00: rgba(255, 255, 255, 0);
$color-white-07: rgba(255, 255, 255, 0.07);
$color-white-25: rgba(255, 255, 255, 0.25);
$color-white-50: rgba(255, 255, 255, 0.5);
$color-white-75: rgba(255, 255, 255, 0.75);
$color-white-90: rgba(255, 255, 255, 0.9);

// ####################
$color-product-card-background-available: rgba(5, 184, 136, 0.1);

// FONT FAMILY
$font-family-helvetica: "Helvetica Neue", "Helvetica", Helvetica, Arial,
  sans-serif;
$font-family-inter: "Inter", Arial, Helvetica, sans-serif;
$font-family-roboto: "Roboto", Arial, Helvetica, sans-serif;
$font-family-roboto-mono: "Roboto Mono";
$font-family-rubik: "Rubik", Helvetica, Arial, sans-serif;
$font-family-rasa: "Rasa", Georgia, Garamond, serif;
$font-family-source-sans-pro: "Source Sans Pro", "Helvetica Neue", Helvetica,
  Arial, sans-serif;
$font-family-quantico: "Quantico", "Helvetica Neue", helvetica, arial,
  sans-serif;

// FONT WEIGHT
$font-weight-extra-light: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

// -- Bases -- //
$color-navy-black: #102028;
$color-off-black: #0d3244;
$color-bright-blue: #0090ba;

// -- tools/overview -- //
$color-light-grayish-cyan: #f3fbfc;
$color-light-grayish-violet: #f8f5fa;

// -- tools/excel -- //
$color-green-dark: #277e4f;
$color-light-green: #567e5e;
$color-dark-green: #1d5136;
$color-darker-green: #214c2f;

// -- tools/r -- //
$color-light-purple: #56426b;
$color-r-purple: #3b2d4b;
$color-r-hero-light: #565b6b;
$color-r-hero-dark: #282b35;

// -- tools/python -- //
$color-python-green: #3b5847;
$color-python-blue: #0f4377;
$color-python-dark-blue: #0f364d;

// -- tools-excel -- //
$color-excel-green: #147445;

// -- tools-ruby --//
$color-ruby-red: #b11101;
$color-ruby-red-dark: #920d00;

// -- rainbow-colorized-code -- //
$color-navy-purple: #1900ca;
$color-turquoise: #00a792;
$color-dark-red: #ce3008;
$color-light-blue: #e5f0f6;

// -- Other Colours -- //
// When there are too many of these they should be consolidated and replaced
$color-internal-catalogue-group-bg: #cc5cb7;
$org-db-copy-green: #00bf3f;
$org-db-api-box-background: #e9ecef;
$org-db-color-teal: #137a9a;
$org-db-color-orange: #ff970d;
$color-dataset-border: #e8f2f4;
$color-dataset-border-selected: #99c8dc;

// GRADIENTS
$gradient-assets-banner: linear-gradient(102deg, #1c818c, #501b73 100%);
$gradient-overlapping-box-blue: linear-gradient(112deg, #31c6ca, #692a99 98%);
$gradient-overlapping-box-red: linear-gradient(115deg, #dd591d, #9922ae);
$gradient-step-card: linear-gradient(to right, #f3fbfc, #f8f5fa 100%);
$gradient-about-quandl-header: linear-gradient(80deg, #1c818c 3%, #501b73 100%);
$gradient-ureb-banner: linear-gradient(
  100deg,
  #6db43e -1%,
  #00529a 100%,
  #00529a 100%
);
$gradient-managed-data-small: linear-gradient(to right, #6db43e, #00529a);
$gradient-publisher-page-banner: linear-gradient(
  to right,
  #5b1a71 0,
  #452476 24%,
  #2c3f7d 49%,
  #1d8390 63%,
  #218f96 75%
);
// #########################
$gradient-main-header: linear-gradient(
  to right,
  #5b1a71 0%,
  #452476 24%,
  #2c3f7d 49%,
  #1d8390 63%,
  #218f96 75%
);
$gradient-api-reference-banner: linear-gradient(102deg, #1c818c, #501b73 100%);

// SHADOWS
$color-landing-page-box-shadow: rgba(27, 27, 27, 0.22);
$docs-help-card-shadow: 0 15px 12px rgb(27 27 27 / 22%);
$color-black-25: rgba(13, 50, 68, 0.25);
$color-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
$card-box-shadow: 0 0 7px 0 rgb(0 0 0 / 9%);
$support-request-box-shadow: 0 15px 12px 0 rgba(27, 27, 27, 0.22);
$color-selector-shadow: 0 0 8px 0 rgb(6 64 83 / 10%);
$organization-card-shadow: 0 2px 4px 0 rgb(13 50 68 / 50%);
$popover-shadow: 0 15px 12px 0 rgb(27 27 27 / 22%);
$color-help-icon-shadow: rgba(0, 0, 0, 0.3);
$color-help-icon-shadow-hover: rgba(0, 0, 0, 0.5);
$color-table-cta-shadow: #ececec;
$floating-icon-link-box-shadow: 5px 5px 15px rgba(13, 50, 68, 0.16);
$floating-icon-circle-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
$delete-button-shadow: inset 0 0 0 1px rgba(13, 50, 68, 0.16);
