.radio-checkboxes-group {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr 1fr;

  /* override default form input label */
  & > label {
    grid-column: span 3;
  }
}
