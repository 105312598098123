// Assumes lag inputs have the same structure - value field + unit dropdown.
// A reusable lag input component would be ideal, but doesn't seem worth it at the moment
// because there are only 2 lag inputs. Hence the quick css fix here.
.lag-input {
  display: flex;
  max-width: 240px;
  gap: 0.5rem;

  > div:first-child {
    width: auto;
    flex: 1 0 30%;
  }

  > div:last-child {
    width: auto;
    flex: 2 1 50%;
  }
}
