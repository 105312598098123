.container {
  padding: 2rem;
}

.datatables {
  width: 100%;
}

.actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 2rem;
}

.loading {
  padding: 0.375rem 2rem;
}

.empty {
  padding: 40px 0;
  border-bottom: 1px #adadad solid;
}
