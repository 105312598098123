.header {
  display: grid;
  align-items: center;
  padding: 0.375rem 2rem;
  border-bottom: 1px solid #adadad;
  column-gap: 1rem;
  grid-template-columns: 36px 4fr 1fr 1fr 135px;
  grid-template-rows: auto auto;
}

.sorter {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.75rem;

  &--active {
    color: #29a3c5;

    i {
      color: #29a3c5;
    }
  }
}

.name-column {
  grid-column: 1 / 3;
}
