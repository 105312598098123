@import "../general.scss";

.sec-sub-nav {
  height: 2.125rem;
  background-color: #363636;
  z-index: 50;
  overflow: hidden;
  color: $color-white;
  font-family: $font-family-source-sans-pro;
  height: 4.875rem;

  &__container {
    display: flex;
    height: 100%;
    padding: 0 20px;
    max-width: 1180px;
    margin: 0 auto;

    &-heading {
      display: flex;
      border-right: 1px solid $color-white;
      align-items: center;
      flex-grow: 0;
      min-width: 7.5rem;

      .back-icon {
        margin-right: 0.3125rem;
      }

      .sec-sub-nav__title {
        font-size: 1.5rem;
        line-height: 0.9375rem;
        font-weight: $font-weight-normal;
        margin-left: 1.125rem;
        margin-right: 2.3125rem;

        @media #{$media-extra-wide-lg} {
          font-size: 2rem;
          line-height: normal;
        }

        @media #{$media-extra-wide-expanded} {
          font-size: 1rem;
        }
      }
    }

    &-items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;

      &-list {
        display: flex;
        list-style-type: none;
      }

      .list-link-style {
        color: $color-white;
        font-weight: $font-weight-normal;
        margin-right: 1.875rem;

        &:hover {
          text-decoration: none;
        }

        @media #{$media-extra-wide-md} {
          font-size: 1rem;
          line-height: 1.375rem;
        }

        @media #{$media-extra-wide-lg} {
          font-size: 1rem;
          line-height: 1.375rem;
        }

        @media #{$media-extra-wide-expanded} {
          font-size: 1rem;
          line-height: 1.375rem;
        }
      }

      .bookmark-button_circle {
        background-color: $color-white;
        padding: 0.375rem 0.375rem 0.125rem;
        border: none;
        border-radius: 50%;
      }

      .bookmark-button__icon {
        height: 0.9375rem;
        display: inline-block;
        width: 1.125rem;
        font-size: 0.9375rem;
      }
    }
  }
}
