@import "../../styles/variables";

.record {
  display: grid;
  align-items: center;
  padding: 1.5625rem 2rem;
  grid-gap: 1rem;
  grid-template-columns: 36px 4fr 1fr 1fr 135px;
}

.logo {
  width: 36px;
  height: 36px;
  margin: 0 auto;
  background-color: #c4c4c4;
  font-size: 0.6rem;

  img {
    width: 100%;
  }
}

.title {
  font-size: 1.1rem;
  font-weight: 700;

  a {
    color: black;
    font-family: $font-family-rubik;
    font-weight: 700;

    &:hover {
      color: #333;
      text-decoration: none;
    }
  }
}

.badge {
  display: inline-block;
  margin-right: 5px;
}

.description {
  margin-top: 0.5rem;
  font-family: $font-family-source-sans-pro;
  font-size: 0.875rem;
}

.info {
  color: #595959;
  font-size: 0.6875rem;
  text-transform: uppercase;
}

.actions {
  font-size: 0.875rem;
}

.last-saved-at {
  margin-top: 5px;
}

.action {
  padding: 0.75rem 1.4rem;
  font-size: 0.875rem;

  &:hover {
    text-decoration: none;
  }
}

.delete {
  border: none;
  background: none;
  cursor: pointer;

  i {
    color: $error;
  }
}

.disable-data {
  border: none;
  background: none;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }

  img {
    vertical-align: middle;
  }
}
