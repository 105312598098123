@import "../../styles/variables";

.page {
  padding: 2rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 0;

  a {
    display: flex;
    align-items: center;
    color: #1b1b1b;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    gap: 0.5rem;
    letter-spacing: 1px;
    line-height: 14px;
    text-transform: uppercase;

    &:hover {
      color: #1b1b1b;
      text-decoration: none;
    }
  }
}

.back-link {
  text-transform: capitalize;
}

.title-container {
  display: flex;
  align-items: center;
  margin-top: 46px;
  margin-bottom: 22px;

  .header-button {
    display: flex;
    max-width: 590px;
    border: none;
    margin: 0 8px 0 0;
    color: #000;
    cursor: pointer;
    font-family: $font-family-rubik;
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    padding-block-end: 2px;
    text-wrap: nowrap;

    &:empty::before {
      color: #949494;
      content: attr(data-placeholder);
    }
  }

  .sub-title-container {
    display: flex;
    margin-left: 2rem;
    gap: 0 2rem;
  }
}
