.page {
  display: flex;
}

.container {
  width: 100%;
  padding: 2rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block-end: 18px;

  a {
    display: flex;
    align-items: center;
    color: #1b1b1b;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    gap: 0.5rem;
    letter-spacing: 1px;
    line-height: 14px;
    text-transform: uppercase;

    &:hover {
      color: #1b1b1b;
      text-decoration: none;
    }
  }
}

.button-container {
  display: flex;
  gap: 10px;
}

.flex-container {
  display: flex;
}

.edit-user-data {
  p {
    font-size: 25px;
    font-weight: 300;
  }

  padding-bottom: 30px;
}

.edit-user-name {
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 300;
}

.back-link {
  // copied styles for button from <Link> component for parity
  display: flex;
  align-items: center;
  color: #1b1b1b;
  cursor: pointer;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  gap: 0.5rem;
  grid-gap: 0.5rem;
  letter-spacing: 1px;
  line-height: 14px;
  text-transform: capitalize;
  text-transform: uppercase;
}

.products-container {
  flex-grow: 3;
  padding-right: 20px;
}

.user-groups-container {
  flex-grow: 1;
}
