@import "../../styles/variables";

.container {
  display: flex;
}

.content-section {
  width: 100%;
  padding: 2rem;
}

.vendor {
  display: flex;
  justify-content: space-between;
}

.heading {
  margin-top: 0;
  margin-bottom: 0;
  color: #000;
  font-family: $font-family-roboto;
  font-feature-settings: "liga" off;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
}

.buttons-container {
  display: flex;
  gap: 20px;
}

.chevron-icon {
  margin-left: 7px;
}

.circle-icon {
  margin-right: 7px;
  font-size: 0.8rem;
}

.content {
  display: flex;
  flex-direction: column;
  font-family: $font-family-roboto;
}

.metadata {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.label {
  margin-right: 5px;
  font-weight: bolder;
}
