.icon {
  margin-top: -0.125rem;
  cursor: pointer;
  font-size: 1rem;
}

.tool-tip {
  max-width: 400px;
  font-weight: normal;
  pointer-events: auto !important;
  white-space: normal;

  &:hover {
    opacity: 1 !important;
  }
}
