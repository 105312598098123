@import "../general.scss";

.mac-style {
  height: 34px;
  background-color: #262b2fcc;
  z-index: 50;
  overflow: hidden;
  color: $color-white;
  font-family: $font-family-source-sans-pro;
  border-top-right-radius: 21px;
  border-top-left-radius: 21px;

  .mac-dots-container {
    display: flex;
  }

  .mac-dots {
    background: #a8a8a8;
    height: 12px;
    width: 12px;
    border-radius: 100%;
    margin-right: 5px;
  }

  &__container {
    display: flex;
    height: 100%;
    margin: 0 22px;

    &-items {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__text {
    color: $color-white;
    font-weight: $font-weight-normal;
    margin-right: 1.875rem;

    @media #{$media-extra-wide-md} {
      font-size: 1rem;
      line-height: 1.375rem;
    }

    @media #{$media-extra-wide-lg} {
      font-size: 1rem;
      line-height: 1.375rem;
    }

    @media #{$media-extra-wide-expanded} {
      font-size: 1rem;
      line-height: 1.375rem;
    }
  }
}

.prod-nav-container {
  height: 46px;
  z-index: 50;
  overflow: hidden;
  font-family: $font-family-roboto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 10px;

  .prod-nav-container-left {
    display: flex;
    padding: 10px 0;

    .nav-logo {
      border-right: 1px solid #a6aaad;
      background-color: #fff;
    }

    .link {
      color: $color-top-bar-brown;
      font-weight: $font-weight-light;
      font-size: 13px;
      white-space: nowrap;
      text-transform: uppercase;
      text-decoration: none;
      background-color: $color-white;

      &--list-item {
        padding-left: 20px;
        border: 0;
        font-family: $font-family-roboto;
        width: 100%;
        text-align: initial;
        &:hover {
          color: $color-top-bar-brown;
          text-decoration: none;
          background-color: $color-faint-grey;
        }
      }

      &--brand {
        color: $color-grey;
        display: flex;
        align-items: center;
        font-size: 16px;
        text-transform: capitalize;
        margin-right: 40px;
        padding: 0px 14px;
      }
    }

    .search-container {
      display: inline-flex;
      @media only screen and (max-width: 47.999em) {
        display: none;
      }
    }

    .search-bar {
      display: flex;
      margin-right: 10px;
    }

    .search-icon {
      display: flex;
      align-items: center;
      padding: 6px 14px;
      border: 1px solid $color-grey-light;
      background: #fff;
      &:hover {
        text-decoration: none;
      }
    }

    .input-bar {
      display: flex;
      align-items: center;
      padding: 9px 58px 12px 10px;
      border: solid 0.9px $color-grey-light;
      background-color: $color-grey-light;
      color: $color-black;
      font-size: 12px;
      font-weight: $font-weight-normal;
    }
  }

  .prod-nav-container-right {
    display: flex;
    align-items: center;
    gap: 30px;

    .button-container {
      display: flex;
      align-items: center;
      gap: 15px;

      .dropdown-menu-link {
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 10px;
        border-radius: 2.28px;
        border: 0.76px solid #1b1b1b;
        background: var(--gray-000, #fff);

        p {
          margin: 0;
          color: #1d1d1d;
          text-align: center;
          font-family: Roboto;
          font-size: 11.2px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: 1px;
          text-transform: uppercase;
        }

        &:hover {
          text-decoration: none;
        }
      }

      .blue-link {
        border: 0.76px solid #0094bd;
      }

      .yellow-link {
        border: 0.76px solid #fcb815;
      }
    }
  }
}
