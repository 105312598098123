@import "../../../styles/variables";

.heading {
  margin-top: 0;
  margin-bottom: 0;
  color: #000;
  font-family: $font-family-roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 22px;
  gap: 12px;
}

.label {
  margin-top: 20px;
  margin-bottom: 6px;
  color: #6e6e6e;
  font-family: $font-family-roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.star {
  color: #b0008e;
}

.name {
  margin-top: 0;
}

.icon {
  margin-left: 10px;
  color: #6e6e6e;
  font-size: 10px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
}

div > .modal-header {
  border-bottom: unset;
}
