@import "../../styles/variables";

.content-section {
  padding: 2rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.heading-container {
  display: flex;
  align-items: center;
}

.heading {
  margin-top: 0;
  margin-bottom: 0;
  color: #000;
  font-family: $font-family-roboto;
  font-feature-settings: "liga" off;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
}

.buttons-container {
  display: flex;
  gap: 20px;
}

.circle-icon {
  margin-right: 0.5rem;
  font-size: 0.8rem;
}
