.swiper-slide {
  min-height: 12px;
}

.swiper-pagination {
  .swiper-pagination-bullet {
    width: 15px;
    height: 4px;
    border-radius: 0;
    background: #d9d9d9;
  }

  .swiper-pagination-bullet-active {
    background: #000;
  }
}

.swiper-button-next,
.swiper-button-prev {
  background-color: white;
  background-position: center;
  background-size: 16px;
  background-repeat: no-repeat;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: -5px !important;
  top: 10px !important;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: -5px !important;
  top: 10px !important;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 1 !important;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev {
  background-image: url("../../assets/swiper-left-arrow.svg");
}

.swiper-button-next {
  background-image: url("../../assets/swiper-right-arrow.svg");
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "" !important;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  top: 0px !important;
}

.swiper-pagination {
  position: relative !important;
}

.swiper-wrapper {
  margin-left: 22px;
}
