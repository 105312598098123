.wrapper {
  overflow: auto;
  height: 100%;
  max-height: 100vh; // safeguard to ensure list doesn't load infinitely by itself
}

.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  height: 1px; // ensure IntersectionObserver works
}
