.pagination {
  display: flex;
  justify-content: space-between;
}

.label {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

.navigation {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  text-decoration: none;

  &:hover {
    text-decoration: none;

    .label {
      background-color: #f8f8f8;
    }
  }
}
