.loading-indicator {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgb(0 0 0 / 25%);
  gap: 0.25rem;
  transform: translate(-50%, -50%);
}
