@import "../general.scss";

.product-summary__container {
  flex: 1;
  .product-summary__heading-container {
    display: flex;
    align-items: center;

    .product-summary__heading {
      text-decoration: underline dashed;
      text-underline-offset: 5px;
      font-weight: $font-weight-normal;
      font-size: 16px;
      color: $color-black;
      margin-right: 24px;
      font-family: $font-family-inter;
      font-style: normal;
      line-height: normal;

      @media #{$media-extra-wide-lg} {
        font-size: 16px;
      }

      @media #{$media-extra-wide-expanded} {
        font-size: 24px;
      }
    }

    .product-summary__pill {
      font-size: 12px;
      line-height: 12px;
      background-color: #d1d1d1;
      padding: 4px 7px;
      border-radius: 14px;
      color: $color-black;
      font-weight: $font-weight-medium;
      margin-right: 10px;
    }
  }

  .product-summary__description {
    font-size: 16px;
    line-height: 22px;
    word-break: break-all;
    font-weight: $font-weight-light;
    font-family: $font-family-inter;
  }

  .product-summary__ESG-text {
    color: #000;
    font-weight: $font-weight-light;
    font-family: $font-family-inter;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    margin-top: 16px;

    @media #{$media-extra-wide-lg} {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .product-summary__ESG-icons-container {
    display: flex;
    width: 300px;
    margin-top: 16px;
    gap: 10px;
    flex-wrap: wrap;

    @media #{$media-extra-wide-md} {
      width: 100%;
    }
  }

  .product-summary__ESG-icon {
    height: 75px;
    width: 75px;
    border: 0.5px solid var(--gray-600, #949494);
  }
}
