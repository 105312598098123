@import "../general.scss";

.supporting-documents-section {
  position: relative;
  .supporting-documents-button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 21px;
    gap: 7px;
    padding: 14px 0;
    border-radius: 3px;
    border: 1px solid #bcbcbc;
    color: #6e6e6e;
    background-color: #fff;
    text-align: center;
    font-family: $font-family-roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: relative;
    cursor: pointer;
  }

  .dropdown-menu-container {
    position: absolute;
    width: 100%;
    background-color: $color-white;
    z-index: 10000;
    border-bottom: 0.5px solid var(--gray-800, #d1d1d1);
    box-shadow: 0px 1px 5px 0px rgba(129, 129, 129, 0.2),
      0px 3px 1px 0px rgba(129, 129, 129, 0.12),
      0px 2px 2px 0px rgba(129, 129, 129, 0.14);

    .dropdown-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      padding: 0 13px;
      height: 65px;

      border-bottom: 0.5px solid var(--gray-600, #949494);
      border-left: 0.5px solid var(--gray-800, #d1d1d1);
      border-right: 0.5px solid var(--gray-800, #d1d1d1);

      .dropdown-menu-text {
        font-size: 14px;
        font-weight: $font-weight-normal;
        font-family: $font-family-inter;
        color: var(--gray-200, #595959);
        font-style: normal;
        line-height: normal;
        margin: 0;
      }

      .dropdown-menu-link {
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 10px;
        border-radius: 2.28px;
        background: #d1d1d1;
        color: #949494;

        p {
          margin: 0;
          text-align: center;
          font-family: Roboto;
          font-size: 11.2px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: 1px;
          text-transform: uppercase;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
