.button {
  display: block;
  padding: 0;
  border: none;
  margin: 0 auto;
  background-color: transparent;
  color: #0092bc;
  cursor: pointer;
  line-height: 0;
}

.ghost {
  background-color: #c8ebfb;
}
