@import "../../../styles/variables";

.page {
  display: flex;
}

.container {
  width: 100%;
  padding: 2rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 0;

  a {
    display: flex;
    align-items: center;
    color: #1b1b1b;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    gap: 0.5rem;
    letter-spacing: 1px;
    line-height: 14px;
    text-transform: uppercase;

    &:hover {
      color: #1b1b1b;
      text-decoration: none;
    }
  }
}

.back-link {
  text-transform: capitalize;
}

.header button {
  font-weight: 400;
}

.error {
  color: #f8333c;
}

.tabs-container button {
  display: inline-block;
  padding: 0 0 3px;
  border: none;
  background-color: #fff;
  border-block-end: 3px solid transparent;
  color: #949494;
  cursor: pointer;
  font-size: 20px;

  &.active {
    border: none;
    border-block-end: 3px solid #28b0d6;
    color: #28b0d6;
    font-weight: 500;
  }
}

.header button + button {
  margin-inline-start: 42px;
}

.tabs-container button + button {
  margin-inline-start: 27px;
}

.title-container {
  display: flex;
  align-items: center;
  margin-top: 46px;
  margin-bottom: 22px;

  .header-button {
    display: flex;
    max-width: 590px;
    border: none;
    margin: 0 8px 0 0;
    color: #000;
    cursor: pointer;
    font-family: $font-family-rubik;
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    padding-block-end: 2px;
    text-wrap: nowrap;

    &:empty::before {
      color: #949494;
      content: attr(data-placeholder);
    }
  }

  .header {
    padding-right: 5px;
  }

  .icon {
    font-size: 12px;
  }

  .text-input {
    overflow: hidden;
    max-width: 590px;
    border: none;
    margin: 0 8px 0 0;
    color: #000;
    font-family: $font-family-rubik;
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    padding-block-end: 2px;
    text-wrap: nowrap;

    &:empty::before {
      color: #949494;
      content: attr(data-placeholder);
    }
  }

  span {
    margin-inline-start: 36px;
  }
}

.title-container .logo-upload-button i {
  position: relative;
  top: 8px;
  right: 6px;
  margin-inline-start: 1px;
}

.remove-logo-button {
  position: relative;
  top: -9px;
  left: -15px;
}

.tabs-container {
  border-block-end: 0.5px solid #adadad;
  margin-block-end: 10px;
}

.title-container .logo-upload-button input {
  display: none;
}
