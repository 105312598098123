.flex {
  display: flex;
  gap: 20px;
}

.product-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-block-end: 0.5px solid #e6e6e6;
  border-block-start: 0.5px solid #e6e6e6;
  margin-block-end: 12px;
}

.product-title {
  display: flex;
  align-items: center;
  gap: 12px;

  h4 {
    margin: 0;
  }
}

.product-content {
  padding: 1rem;
  padding-top: 1rem !important;
  background-color: #fcfcfc;
}

.existing-product-card {
  & > :first-child {
    display: none;
  }

  & > :last-child {
    padding-block-start: 0;
  }
}

.product-drawer-container {
  margin-bottom: 4.5rem;
}
