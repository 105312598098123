.card-wrapper {
  padding: 1rem 1rem 1.5rem;
  background: var(--gray-010, #f8f8f8);
}

.filters {
  padding-top: 1rem;
  border-top: 0.5px solid #d8d8d8;
  margin-top: 1.5rem;
}
