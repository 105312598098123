.container {
  padding-right: 2rem;
  padding-left: 2rem;
}

.header-bar {
  display: flex;
  gap: 1rem;
}

.categories {
  display: flex;
  flex: 1 0 15%;
  flex-wrap: wrap;
  gap: 20px;
}
