@import "../styles/variables";

.toast {
  padding-left: 10px;
}

.border {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 8px;
  border-radius: 3px 0 0 3px;
}

.border-success {
  background: $success;
}

.border-info {
  background: $info;
}

.border-error {
  background: $error;
}

.header {
  margin-bottom: 0.5rem;
  font-family: $font-family-roboto;
  font-size: 0.875rem;
  text-transform: uppercase;
}

.icon {
  margin-right: 5px;
}

.header-success {
  color: $success;

  .icon {
    color: $success;
  }
}

.header-info {
  color: $info;

  .icon {
    color: $info;
  }
}

.header-error {
  color: $error;

  .icon {
    color: $error;
  }
}

.detail + .detail {
  margin-top: 0.5rem;
}

.message {
  color: #212529;
  font-family: $font-family-source-sans-pro;
  font-size: 0.875rem;
}

.description {
  color: #6c757d;
  font-family: $font-family-source-sans-pro;
  font-size: 0.75rem;
}
