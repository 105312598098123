@import "../../../../styles/variables";

.container {
  overflow: hidden;
  width: 100%;
  height: 660px;
  border: 0.5px solid #adadad;
  border-radius: 8px;
}

.sorter-active {
  color: var(--primary-300, #00789a);
}

.caret-icon {
  margin-bottom: 2.5px;
  color: var(--primary-300, #00789a) !important;
}

.center {
  display: flex;
  justify-content: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-block-end: 0.5px solid #949494;

  span {
    font-weight: 500;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
  }
}

.values {
  width: 160px;
  padding: 30px 0;
  color: #000;
  font-family: $font-family-roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;

  &:hover {
    text-decoration: none;
  }
}

.action-buttons {
  border: 0 !important;

  &:disabled {
    background-color: transparent;

    & > i::before {
      color: #949494;
    }

    &:hover {
      background-color: #d1d1d1;
    }
  }
}

.users-button {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  color: var(--primary-300, #00789a);

  &:hover {
    cursor: pointer;
  }
}

.table-container {
  overflow: auto;
  height: 600px;
  padding: 0 1rem 15px;
  margin-top: 15px;

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      position: sticky;
      top: 0;
      background: #fff;
      text-transform: uppercase;

      th {
        padding-bottom: 1rem;
        border-block-end: 0.5px solid #949494;

        button {
          padding: 0;
          border: 0;
          background-color: #fff;
          cursor: pointer;
          font-size: 11px;
          font-weight: 600;
        }

        &.selected {
          > button,
          > i {
            color: #00789a;
          }
        }
      }
    }

    tbody {
      td {
        padding: 1.5rem 0;
        border-block-end: 0.5px solid #949494;

        .user-actions {
          display: flex;
          justify-content: space-evenly;
          text-align: end;
        }
      }
    }
  }
}
