@import "../../styles/variables";

.container {
  display: flex;
  padding: 0;
  margin: -1rem;
}

.sidebar {
  position: fixed;
  top: 59px;
  bottom: 0;
  width: 226px;
  flex-shrink: 0;
  background: linear-gradient(
      0deg,
      rgb(92 185 212 / 3%) 0%,
      rgb(92 185 212 / 3%) 100%
    ),
    #fff;
  box-shadow: 0 1px 5px 0 rgb(129 129 129 / 20%),
    0 3px 1px 0 rgb(129 129 129 / 12%), 0 2px 2px 0 rgb(129 129 129 / 14%);
}

.list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: 18px;
  margin-bottom: 43px;
  gap: 18px;
  list-style: none;
}

.margin {
  margin-top: 16px;
}

.list-item {
  height: 17px;
  padding-left: 26px;
  color: #000;
  cursor: pointer;
  font-family: $font-family-inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.5px;

  &:hover {
    padding-left: 21px;
    border-left: 5px solid #1b1b1b;
    color: #000;
    font-weight: 600;
    text-decoration: none;
  }
}

.active-link {
  padding-left: 21px;
  border-left: 5px solid #1b1b1b;
  color: #000;
  font-weight: 600;
  text-decoration: none;
}

.profile-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: 18px;
  margin-bottom: 18px;
  gap: 18px;
  list-style: none;
}

.sidebar-container {
  margin-top: 45px;
}

.dropdown-container {
  width: 175px;
  margin-top: 45px;
  padding: 0 26px;
}

.dropdown-label {
  color: #949494;
  font-family: $font-family-inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.5px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 7px;
}

.sidebar-heading {
  padding-left: 26px;
  margin-top: 45px;
  color: #949494;
  font-family: $font-family-inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.5px;
  text-transform: uppercase;
}

.logout-container {
  margin-top: 107px;
}

.back-icon {
  width: 24px;
  height: 16px;
  flex-shrink: 0;
}

.logout-text {
  margin-left: 14px;
}

button {
  all: unset;
}

.chevron-icon {
  margin-left: 5px;
}
