@import "../../styles/variables";

table {
  width: 100%;
  border-spacing: 0;

  th {
    &:hover {
      cursor: pointer;
    }

    &.selected {
      > button,
      > i {
        color: #00789a;
      }
    }
  }

  td {
    border-top: 0.5px solid var(--gray-600, #949494);
  }

  .label {
    padding-bottom: 8px;
    color: #000;
    font-family: $font-family-inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
  }

  .name {
    color: var(--primary-300, #00789a);
    font-size: 11px;
  }

  .values {
    width: 160px;
    padding: 30px 0;
    color: #000;
    font-family: $font-family-roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;

    &:hover {
      text-decoration: none;
    }
  }

  .left-align {
    width: 400px;
    text-align: left;
  }

  .right-align {
    display: flex;
    justify-content: flex-end;
  }
}

.sorter-active {
  color: var(--primary-300, #00789a);
}

.caret-icon {
  margin-bottom: 2.5px;
  color: var(--primary-300, #00789a) !important;
}

.link {
  color: var(--charts-dual-gray-70, #4d4d4d);

  &:hover {
    color: var(--charts-dual-gray-70, #4d4d4d);
    text-decoration: none;
  }
}
