.search-container {
  position: relative;
}

.checkbox {
  position: absolute;
  top: 0;
  right: 300px;
  display: flex;
  flex-direction: row-reverse;

  div {
    margin-right: 10px;
  }

  small {
    margin-top: 0;
    margin-right: 10px;
  }
}
