@import "../../../../styles/variables";

.accordian-container {
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.accordian-tab {
  display: flex;
  width: 90%;
  justify-content: space-between;
  padding: 1rem;
  font-size: 18px;
  font-weight: 300;

  .circle-icon-blue {
    color: $info;
    font-size: 1.5rem;
  }
}

.accordian-content {
  width: 90%;
  padding-bottom: 1rem;
}
