@import "../../styles/variables";

.context-menu {
  position: absolute;
  z-index: 1;
  top: 80%;
  right: 10%;
  border: 1px solid #adadad;
  background-color: #fff;
}

.relative {
  position: relative;
}

.button {
  display: inline;
  padding: 0;
  border: none;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
}

.context-menu-item {
  padding: 0.875rem;
  color: #29a3c5;
  text-decoration: none;

  &:hover {
    border-color: rgb(0 146 188);
    background-color: rgb(61 189 225);
    color: #fff;
  }
}

.context-toggle {
  padding: 1rem 0.25rem;
}

.table {
  width: 100%;
  height: 1px;
  border: 0;
  border-collapse: collapse;
  font-family: $font-family-roboto;
}

.table-body {
  font-size: 1.125rem;
  font-weight: 300;
}

.table-row {
  border-bottom: 1px solid #adadad;

  td {
    padding: 1rem 0;

    &.action-cell {
      padding: 0;
      text-align: end;
    }
  }

  th {
    padding: 0.75rem 0;
  }

  &:hover {
    background: #f8f9fa;
  }
}

.directory-row {
  cursor: pointer;

  &:focus {
    background: #ccc;
  }
}
