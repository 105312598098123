@import "../general.scss";

.product-pricing-section {
  flex: 1;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg id='10015.io' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cpattern id='svg-pattern' x='0' y='0' width='6' height='6' patternUnits='userSpaceOnUse' patternTransform='translate(4, 4) rotate(0) skewX(0)'%3E%3Csvg width='2' height='2' viewBox='0 0 100 100'%3E%3Cg fill='%23d2d6db' opacity='0.25'%3E%3Ccircle cx='50' cy='50' r='50'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E%3C/pattern%3E%3C/defs%3E%3Crect x='0' y='0' width='100%25' height='100%25' fill='%23ffffff'%3E%3C/rect%3E%3Crect x='0' y='0' width='100%25' height='100%25' fill='url(%23svg-pattern)'%3E%3C/rect%3E%3C/svg%3E");

  .personal-pricing-section {
    .personal-pricing-container {
      padding: 18px 18px 30px;
      border-radius: 4px;
      border: 0.2px solid #adadad;

      .personal-pricing-heading {
        color: var(--gray-900, #1b1b1b);
        font-family: $font-family-roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 0;
      }

      .personal-pricing-description {
        color: var(--gray-900, #1b1b1b);
        font-family: $font-family-roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-top: 0;
        margin-bottom: 0;

        b {
          color: var(--gray-900, #1b1b1b);
          font-family: $font-family-roboto;
          font-size: 36px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .personal-pricing-button {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 7px;
        padding: 14px 0;
        border-radius: 3px;
        background: #d1d1d1;
        color: #949494;
        text-align: center;
        font-family: $font-family-roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-top: 25px;
        margin-bottom: 38px;
      }

      .personal-pricing-sub-heading {
        color: var(--gray-000, #1b1b1b);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;

        svg {
          margin-left: 7px;
        }
      }
    }
  }
}
