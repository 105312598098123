@import "../../styles/variables";

.page {
  display: flex;
}

.container {
  width: 100%;
  padding: 2rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block-end: 18px;

  a {
    display: flex;
    align-items: center;
    color: #1b1b1b;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    gap: 0.5rem;
    letter-spacing: 1px;
    line-height: 14px;
    text-transform: uppercase;

    &:hover {
      color: #1b1b1b;
      text-decoration: none;
    }
  }
}

.back-link {
  text-transform: capitalize;
}

.header button {
  font-weight: 400;
}

.error {
  color: #f8333c;
}

.tabs-container button {
  display: inline-block;
  padding: 0 0 3px;
  border: none;
  background-color: #fff;
  border-block-end: 3px solid transparent;
  color: #949494;
  cursor: pointer;
  font-size: 20px;

  &.active {
    border: none;
    border-block-end: 3px solid #28b0d6;
    color: #28b0d6;
    font-weight: 500;
  }
}

.header button + button {
  margin-inline-start: 42px;
}

.tabs-container button + button {
  margin-inline-start: 27px;
}

.title-container {
  display: flex;
  align-items: center;
  margin-block-end: 24px;

  .logo-upload-preview-image {
    width: 42px;
    height: 42px;
    object-fit: cover;
  }

  .logo-upload-button {
    width: 42px;
    height: 42px;
    box-sizing: border-box;
    padding: 8px 5px 8px 8px;
    border: 1px dashed #8c8c8c;
    background: #fff;
    color: #000;
    cursor: pointer;
    font-size: 8px;
    font-style: normal;
    line-height: 12px;
    margin-inline-end: 11px;
    text-align: left;

    &:has(.logo-upload-preview-image) {
      padding: 0;
      border: 0;
    }

    &:hover:not(:has(.logo-upload-preview-image)) {
      border: 1px dashed #000;
    }

    :last-child {
      margin-inline-start: 1px;
    }
  }

  .header-button {
    display: flex;
    max-width: 590px;
    border: none;
    margin: 0 8px 0 0;
    color: #000;
    cursor: pointer;
    font-family: $font-family-rubik;
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    padding-block-end: 2px;
    text-wrap: nowrap;

    &:empty::before {
      color: #949494;
      content: attr(data-placeholder);
    }
  }

  .header {
    padding-right: 5px;
  }

  .icon {
    font-size: 12px;
  }

  .text-input {
    overflow: hidden;
    max-width: 590px;
    border: none;
    margin: 0 8px 0 0;
    color: #000;
    font-family: $font-family-rubik;
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    padding-block-end: 2px;
    text-wrap: nowrap;

    &:empty::before {
      color: #949494;
      content: attr(data-placeholder);
    }
  }

  span {
    margin-inline-start: 28px;
  }
}

.description-text-input-container {
  display: flex;
  max-width: 760px;
  max-height: 80px;
  color: #949494;
  cursor: pointer;
  margin-block-end: 36px;

  p {
    overflow: hidden;
    max-width: 759px;
    border: none;
    margin: 0 8px 0 0;
    font-family: $font-family-rubik;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
  }

  i {
    height: 20px;
    font-size: 12px;
  }
}

.title-container .logo-upload-button i {
  position: relative;
  top: 8px;
  right: 6px;
  margin-inline-start: 1px;
}

.remove-logo-button {
  position: relative;
  top: -9px;
  left: -15px;
}

.tabs-container {
  border-block-end: 0.5px solid #adadad;
  margin-block-end: 10px;
}

.title-container .logo-upload-button input {
  display: none;
}
