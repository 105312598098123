.action-container {
  display: flex;
}

.tabs {
  min-height: 240px;
  padding: 0 1rem;
  background-color: #fcfcfc;
}

.action-menu-container {
  display: flex;
  align-items: center;
  justify-content: center;

  & .icon-offset {
    right: 40px;
  }
}

.copy-filter-button {
  padding-right: 35px;
  padding-left: 35px;

  &:hover {
    cursor: pointer;
  }
}

.tab-code {
  padding-right: 40px;
}

/* stylelint-disable selector-pseudo-class-no-unknown */
:global(.overflow-tabs-wrapper) {
  height: 40px !important;
}
