@import "../../../styles/variables";

.counter {
  color: #1d1e25;
  color: #6e6e6e;
  font-family: $font-family-roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.25rem;
}

.warning {
  color: #f78117;
}

.exceeded {
  color: #f8333c;
}
