@import "./general.scss";

$preview-sidebar-width: 25%;
$preview-color-white: #fff;
$preview-color-grey-medium: #e6e6e6;
$preview-color-grey-accent: #a7a9ac;
$preview-color-grey-extra-dark: #1b1b1b;
$preview-color-black: #000;
$preview-color-purple-blue: #594099;
$preview-color-blue-grey-extra-light: #f7fafc;
$preview-color-blue-grey-light: #edf1f5;
$preview-color-blue-grey: #d0d8df;
$preview-color-bright-blue: #0090ba;
$preview-font-family-rubik: "Rubik", helvetica, arial, sans-serif;
$preview-font-family-roboto: "Roboto", helvetica, arial, sans-serif;
$preview-font-family-roboto-mono: "Roboto Mono", helvetica, arial, sans-serif;
$preview-font-family-source-sans-pro: "Source Sans Pro", "Helvetica Neue",
  helvetica, arial, sans-serif;
$preview-bg-product-name: linear-gradient(
  to right,
  #5b1a71 0,
  #452476 24%,
  #2c3f7d 49%,
  #1d8390 63%,
  #218f96 75%
);
$preview-font-weight-light: 300;
$preview-font-weight-normal: 400;
$preview-font-weight-semi-bold: 600;
$preview-font-weight-bold: 700;

/* End of Preview-specific variables */

.preview {
  overflow: auto;
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 2px rgb(129 129 129 / 14%), 0 3px 1px rgb(129 129 129 / 12%),
    0 1px 5px rgb(129 129 129 / 20%);
  color: $preview-color-black;
  font-size: 14px;
  line-height: 1.5rem;

  * {
    box-sizing: border-box;
  }
}

.preview-inner {
  position: relative;
  min-height: 100%;
}

.body {
  margin-top: 20px;
}

.row {
  display: flex;
  align-items: self-start;
  gap: 20px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: 1200px) {
    max-width: 1180px;
  }

  @media (min-width: 992px) and (max-width: 1119px) {
    max-width: 942px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 728px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    max-width: 546px;
  }
}

.pricing-container {
  flex: 1;
}

.attribute-value-empty {
  font-style: italic;
  opacity: 0.5;
}

.attribute-name {
  color: $preview-color-grey-extra-dark;
  font-family: $preview-font-family-roboto;
  font-size: 13px;
  font-weight: $preview-font-weight-semi-bold;
  letter-spacing: 0.5px;
}

.attribute-value {
  color: $preview-color-grey-extra-dark;
  font-family: $preview-font-family-roboto;
  font-size: 12px;
  text-align: right;
}

.sidebar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: $preview-sidebar-width;
  height: 100%;

  &Inner {
    position: absolute;
    padding: 0.5em 1em;
    background-color: $preview-color-grey-medium;
    box-shadow: 0 0 10px 0 rgb(6 64 83 / 10%);
    inset: 0;
  }
}

.attribute {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 0 8px;
  border-bottom: 1px solid $preview-color-grey-accent;
  font-weight: $preview-font-weight-normal;

  &:last-child {
    border-bottom: 0;
  }

  &-large {
    padding: 20px 0;
    border-bottom: 1px solid #515b66;
    font-size: 18px;
    font-weight: 300;

    .attribute-name {
      font-size: 18px;
      font-weight: 300;
    }

    .attribute-value {
      font-size: 18px;
      font-weight: 300;
    }
  }
}

.description {
  margin: 0.5em 0;

  &Header {
    font-family: Rubik, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: $preview-font-weight-normal;
    text-transform: uppercase;
  }

  &Body {
    margin-bottom: 15px;
    font-family: $preview-font-family-roboto;
    font-size: 18px;
    font-weight: $preview-font-weight-light;
    line-height: 1.4;
  }
}

.documentation__heading {
  text-decoration: underline dashed;
  text-underline-offset: 5px;
  font-weight: $font-weight-normal;
  font-size: 16px;
  color: $color-black;
  margin-right: 24px;
  font-family: $font-family-inter;
  font-style: normal;
  line-height: normal;

  @media #{$media-extra-wide-lg} {
    font-size: 16px;
  }

  @media #{$media-extra-wide-expanded} {
    font-size: 24px;
  }
}

.documentation {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  overflow-x: hidden;

  section + section {
    margin-block-start: 35px;
  }

  .documentation-markdown {
    overflow-y: scroll;
    height: 775px;
    margin: 14px 0;
    padding-right: 60px;
    strong {
      font-size: 16px;
      color: #1b1b1b;
      font-weight: 700;
    }
  }

  h1 {
    color: #1b1b1b;
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 17px;
    margin-top: 2px;
    line-height: normal;
    font-family: $font-family-inter;
  }

  h2,
  h3 {
    color: #1b1b1b;
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: $font-family-inter;
  }

  ul {
    list-style-type: disc;
    padding-left: 25px;
    font-family: $font-family-inter;
    font-weight: 400;
    font-size: 16px;
    color: #1b1b1b;
  }

  p,
  li {
    color: #1b1b1b;
    font-size: 16px;
    line-height: 21px;
    margin: 12px 0;
    font-family: $font-family-inter;
    font-weight: 400;
  }

  a {
    color: #0094bd;

    &:hover {
      color: #29a3c5;
      text-decoration: none;
    }
  }

  p:has(code) {
    max-width: 100%;
  }

  code {
    padding: 16px;
    color: $color-black;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    display: block;
    margin-top: 3px;
    margin-bottom: 5px;
    background: #f8f8f8;
    text-wrap: wrap;
    word-break: break-all;
  }

  li {
    margin: 5px 0;
  }

  img {
    width: 668px;
    height: auto;
    border-radius: 4px;
    padding: 15px;
    border: 0.5px solid #949494;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;

    th {
      padding: 10px 10px 12px 10px;
      background: $color-white;
      color: $color-black;
      font-size: 14px;
      font-family: $font-family-inter;
      font-weight: 700;
      line-height: 18px;
      border-top: 1px solid $color-grey82;
      border-right: 1px solid $color-grey82;
      border-bottom: 1px solid $color-grey-dark;

      &:first-child {
        border-left: 1px solid $color-grey82;
      }

      &:last-child {
        border-right: none;
      }
    }

    tr {
      color: #1b1b1b;
      font-size: 14px;
      font-family: $font-family-inter;
      font-weight: 300;
      line-height: 18px;

      &:nth-child(even) {
        background: #f8f8f8;
      }

      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    td {
      padding: 10px 10px 12px 10px;
      border: 1px solid $color-grey82;

      code {
        width: auto;
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}
