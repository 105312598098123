.section {
  margin-bottom: 1.5rem;
}

.errors {
  height: 165px;
  border: 1px solid #818181;
  overflow-y: auto;
}

.error {
  padding: 0.25rem 0.5rem;
  border-bottom: 1px solid #818181;
  font-size: 0.875rem;
}

.valid {
  color: green;
  font-size: 0.875rem;
}

.footer {
  display: flex;
  gap: 1.5rem;
}
