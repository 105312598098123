@import "../../styles/variables";

.entry-container {
  box-sizing: border-box;
  padding: 0.5rem;
  font-family: $font-family-roboto;
}

.gridview-container-3 {
  display: flex;
  flex-wrap: wrap;

  .entry-container {
    width: 33%;
    max-width: 33%;
    flex-grow: 1;
  }
}

.gridview-container-4 {
  display: flex;
  flex-wrap: wrap;

  .entry-container {
    width: 25%;
    max-width: 25%;
    flex-grow: 1;
  }
}

.entry-body {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  border: 1px solid #949494;
  border-radius: 0.5rem;

  &:hover {
    background: #f8f9fa;
  }

  &:focus {
    background: #ccc;
  }
}

.entry-icon-container {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 3.625rem;
}

.entry-icon-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
}

.entry-name {
  padding: 1.375rem 2rem;
  margin: 0;
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 2rem;
  word-break: break-all;
}

.entry-divider {
  width: 80%;
  height: 1px;
  background-color: #adadad;
}

.entry-info-container {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 0 1.875rem 2rem;
}

.entry-info-left {
  display: flex;
  min-width: 1rem;
  flex-direction: column;
  text-align: start;
}

.entry-info-right {
  display: flex;
  min-width: 1rem;
  flex-direction: column;
  text-align: end;
}

.entry-info-title {
  margin: 1rem 0;
  font-weight: 500;
}

.entry-info-value {
  font-weight: 300;
}

.context-menu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  height: 3rem;
}

.relative {
  position: relative;
  width: 100%;
  height: 100%;
}

.button {
  display: inline;
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
}

.context-menu {
  position: absolute;
  z-index: 1;
  top: 80%;
  right: 10%;
  border: 1px solid #adadad;
  background-color: #fff;
}

.context-menu-item {
  padding: 0.875rem;
  color: #29a3c5;
  text-decoration: none;

  &:hover {
    border-color: rgb(0 146 188);
    background-color: rgb(61 189 225);
    color: #fff;
  }
}
