$font-family-rubik: "Rubik", "Helvetica Neue", helvetica, arial, sans-serif;
$font-family-roboto: "Roboto", "Helvetica Neue", helvetica, arial, sans-serif;
$font-family-source-sans-pro: "Source Sans Pro", "Helvetica Neue", helvetica,
  arial, sans-serif;
$font-family-quantico: "Quantico", "Helvetica Neue", helvetica, arial,
  sans-serif;
$font-family-rasa: "Rasa", "Helvetica Neue", helvetica, arial, sans-serif;
$font-family-inter: "Inter", arial, helvetica, sans-serif;
$success: #00bf3f;
$error: #dc3545;
$info: #0094bd;
$table-border-color: #dee2e6;
$table-text-color: #212529;
