@import "../styles/variables";

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  background-color: #b0008e;
  border-radius: 50rem;
  color: #fff;
  font-family: $font-family-rubik;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  text-transform: capitalize;
  vertical-align: middle;

  & + .badge {
    margin-left: 5px;
  }

  &--waiting-approval {
    background-color: #99cbdc;
  }
}

.cap-product-badge {
  background-color: #4cadce;
}
