@import "../../../../styles/variables";

/* stylelint-disable */
:global(.modal-body) {
  overflow: visible !important;
}
/* stylelint-enable */

.grid {
  display: grid;
  width: 100%;
  align-items: center;
  gap: 0.5rem;
  grid-template-columns: 1fr minmax(auto, 80%);
}

.row {
  grid-column: span 2;
}

.fieldset {
  display: contents;
}

.input {
  min-height: 44px;
  padding: 2px 8px;
  border: 1px solid #ccc;
  margin: 0 0.25rem;
  border-radius: 4px;
  font-family: $font-family-roboto;
  font-size: 0.9rem;
  outline: 0 !important;

  &[type="date"],
  &[type="datetime-local"],
  &[type="time"] {
    padding: 0 0.75rem;
  }

  &[type="number"] {
    padding: 0 0.75rem;
  }

  &[type="radio"] {
    margin: 0 1rem 0 1.5rem;
  }
}

/* stylelint-disable selector-class-pattern */
.input--narrow {
  &[type="number"] {
    width: 50px;
  }
}

.input--nef {
  width: 100%;
  margin: 0 0 1rem;
}
/* stylelint-enable selector-class-pattern */

.input-group {
  display: flex;
  max-width: 100%;
  align-items: center;
}

.preview-text {
  padding: 0.5rem 0;
  color: #818181;
  font-size: 0.8rem;
  grid-column: span 2;
}

// Assumes lag inputs have the same structure - value field + unit dropdown.
// A reusable lag input component would be ideal, but doesn't seem worth it at the moment
// because there are only 2 lag inputs. Hence the quick css fix here.
.lag-input {
  display: flex;
  max-width: 240px;
  gap: 0.5rem;

  > div:first-child {
    width: auto;
    flex: 1 0 30%;
  }

  > div:last-child {
    width: auto;
    flex: 2 1 50%;
  }
}
